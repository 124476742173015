<template>
  <div>
    <headerView />
    <banner class="mt-[3rem] md:mt-[5rem] lg:mt-[4rem]" />
    <ExploreDegree />
    <GetmentorhomeView facultyToShow="" />
    <WhySharda />
    <CareerView />
    <PlacementSupport />
    <BeyondAcademics />
    <aluminaiWorkView />
    <ExploreSharda />
    <AffiliatedView />
    <!-- <OurStudentsView /> -->
    <KnowledgeInsights />
    <certificationVue />
    <enquireView />
    <footerView />
    <stickyView />
  </div>
</template>

<script>
import headerView from "@/components/common/header.vue";
import banner from "@/components/home/banner.vue";
import certificationVue from "../components/home/certification.vue";
import aluminaiWorkView from "@/components/home/aluminaiwork.vue";
import footerView from "@/components/common/footer.vue";
import ExploreSharda from "@/components/home/ExploreSharda.vue";
import PlacementSupport from "@/components/home/PlacementSupport.vue";
import BeyondAcademics from "@/components/home/BeyondAcademics.vue";
import CareerView from "@/components/home/CareerView.vue";
import AffiliatedView from "@/components/home/AffiliatedView.vue";
import enquireView from "@/components/home/enquireView.vue";
import GetmentorhomeView from "@/components/home/GetmentorhomeView.vue";
import WhySharda from "@/components/home/whySharda.vue";
import KnowledgeInsights from "@/components/home/knowledgeInsights.vue";
// import OurStudentsView from "@/components/home/OurstudentsView.vue";
import ExploreDegree from "@/components/home/DegreeView.vue";
import stickyView from "@/components/common/sticky.vue";

export default {
  name: "HomeView",
  components: {
    headerView,
    banner,
    certificationVue,
    aluminaiWorkView,
    footerView,
    ExploreSharda,
    CareerView,
    AffiliatedView,
    PlacementSupport,
    BeyondAcademics,
    enquireView,
    GetmentorhomeView,
    WhySharda,
    KnowledgeInsights,
    // OurStudentsView,
    ExploreDegree,
    stickyView,
  },
};
</script>
<style scoped></style>
