<template>
  <div class="bg-gradient-to-b from-[#FFFFFF] to-[#EFEFEF] py-10 my-10">
    <div class="max-w-7xl m-auto my-8">
      <div
        class="flex flex-col justify-center items-center lg:flex-row-reverse lg:justify-around"
      >
        <div class="w-[20rem] ssm:w-[18rem]">
          <img
            src="@/assets/programm/keytakeway.png"
            alt="keytakeway"
            class="lg:hidden"
          />
          <img
            src="@/assets/programm/keyTakeWayDes.png"
            alt="keytakeway"
            class="hidden lg:block"
          />
        </div>
        <div>
          <div
            class="my-3 ml-4 text-3xl text-[#18479E] w-max lg:text-[60px] lg:ml-0"
          >
            Key <span class="text-[#EC027C]">Takeaways</span>
          </div>
          <div
            class="text-[#4D4D4D] lg:text-[#000000] lg:font-normal font-light ssm:w-[18rem] w-[20rem] m-auto lg:ml-0 mb-4 lg:text-[24px] lg:w-[35rem] my-6"
          >
            Be a prominent name in your field, as you equip yourself with the
            knowledge to thrive in your area of expertise
          </div>
          <div>
            <div class="flex justify-center lg:justify-start gap-x-2 lg:pb-2">
              <div class="w-[.7rem] pt-2">
                <img
                  src="@/assets/home/placementsupport/check.png"
                  alt="check"
                />
              </div>
              <div class="w-[20rem] ssm:w-[18rem] lg:text-[18px] lg:w-max">
                Learn strategic management skills for leadership roles
              </div>
            </div>
            <div class="flex justify-center lg:justify-start gap-x-2 lg:pb-2">
              <div class="w-[.7rem] pt-2">
                <img
                  src="@/assets/home/placementsupport/check.png"
                  alt="check"
                />
              </div>
              <div class="w-[20rem] ssm:w-[18rem] lg:text-[18px] lg:w-max">
                Gain financial expertise for a wide variety of careers
              </div>
            </div>
            <div class="flex justify-center lg:justify-start gap-x-2 lg:pb-2">
              <div class="w-[.7rem] pt-2">
                <img
                  src="@/assets/home/placementsupport/check.png"
                  alt="check"
                />
              </div>
              <div class="w-[20rem] ssm:w-[18rem] lg:text-[18px] lg:w-max">
                Become a master in communication and negotiation skills
              </div>
            </div>
            <div class="flex justify-center lg:justify-start gap-x-2">
              <div class="w-[.7rem] pt-2">
                <img
                  src="@/assets/home/placementsupport/check.png"
                  alt="check"
                />
              </div>
              <div class="w-[20rem] ssm:w-[18rem] lg:w-max lg:text-[18px]">
                Become an expert in data-driven decision making
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "keyTakwayView",
};
</script>

<style scoped></style>
