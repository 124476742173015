<template>
  <div class="max-w-7xl m-auto py-10 lg:py-12">
    <div class="text-[#18479E] w-max m-auto text-3xl md:text-5xl mb-8">
      Our <span class="text-[#EC027C]">Technology</span>
    </div>
    <div class="flex flex-col gap-5 p-4">
      <div
        v-for="(tech, index) in Technology"
        :key="index"
        :id="'info' + index"
        class="flex gap-x-4 lg:gap-x-16 gap-y-6 items-center justify-center"
      >
        <div class="md:hidden">
          <img :src="tech.image" />
        </div>
        <div class="hidden md:block">
          <img :src="tech.imageDes" />
        </div>
        <div class="w-[16rem] md:w-[20rem] md:text-2xl">
          {{ tech.content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ourTechnology",
  data() {
    return {
      Technology: [
        {
          id: 1,
          image: require("@/assets/about/tech1.webp"),
          imageDes: require("@/assets/about/tech1large.webp"),
          content:
            "State-of-the-art Learning Management System (LMS) for a near face-to-face learning experience",
        },
        {
          id: 2,
          image: require("@/assets/about/tech2.webp"),
          imageDes: require("@/assets/about/tech2large.webp"),
          content:
            "Cutting-edge Learning Management System (LMS) providing an immersive learning experience.",
        },
        {
          id: 3,
          image: require("@/assets/about/tech5.webp"),
          imageDes: require("@/assets/about/tech5large.webp"),
          content:
            "Easily address all inquiries through our chat and messaging feature.",
        },
        {
          id: 4,
          image: require("@/assets/about/anytime.webp"),
          imageDes: require("@/assets/about/anytimeLarge.webp"),
          content: "Participate in online forums and peer groups for diverse discussions.",
        },
        {
          id: 5,
          image: require("@/assets/about/tech6.webp"),
          imageDes: require("@/assets/about/tech6large.webp"),
          content: "Instant and convenient access to learning materials at any time.",
        },
      ],
    };
  },
  methods: {
    handleScroll() {
      const elements = document.querySelectorAll('[id^="info"]');
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      elements.forEach((element) => {
        if (
          element.offsetTop <= scrollPosition &&
          element.offsetTop + element.offsetHeight > scrollPosition
        ) {
          element.classList.add("opacity-100");
          element.classList.remove("opacity-30");
        } else {
          element.classList.add("opacity-30");
          
          element.classList.remove("opacity-100");
        }
      });
    },
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll);
    this.handleScroll();


  },
  beforeUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped>
  .opacity-0 {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  .opacity-100 {
    opacity: 1;
  }

</style>
