<template>
    <section>
      <main class="py-5 px-5 md:px-0">
        <!-- Most Popular Faq -->
        <div class="pt-4 py-5 lg:py-4">
          <h1
            class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-center capitalize py-4"
          >
            FAQs
          </h1>
          <div
            class="w-full md:[500px] lg:w-[1000px] mx-auto flex flex-col gap-2 py-[10px] md:py-6"
          >
            <div
              v-for="(item, index) in faq.slice(this.start, this.end)"
              :key="index"
              class="flex flex-col text-left px-[15px] py-[11px] md:px-[25px] md:py-[20px] gap-2 bg-white rounded-lg shadow-sm"
            >
              <div
                class="flex justify-between items-start cursor-pointer text-[#001c54]"
                @click="toggleAccordin(index)"
              >
                <h3
                  class="text-[16px] md:text-md lg:text-[20px] text-[#001C54] Gilroy-SemiBold"
                >
                  {{ item.title }}
                </h3>
                <div>
                  <span
                    v-if="this.activeAccordin !== index"
                    class="pt-[3px] pl-[4px]"
                  >
                  <svg class="svg-inline--fa fa-circle-chevron-down down-arrow text-[#18469d] text-xl" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="text-0-img" alt="down-arrow"><path class="" fill="currentColor" d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM135 241c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l87 87 87-87c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 345c-9.4 9.4-24.6 9.4-33.9 0L135 241z"></path></svg>
                  </span>
                  <span v-else class="pt-[3px] pl-[4px]">
                    <svg class="svg-inline--fa fa-circle-chevron-down down-arrow text-[#18469d] text-xl rotate-180" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="text-0-img" alt="down-arrow"><path class="" fill="currentColor" d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM135 241c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l87 87 87-87c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 345c-9.4 9.4-24.6 9.4-33.9 0L135 241z"></path></svg>
                  </span>
                </div>
              </div>
  
              <p
                class="text-[14px] lg:text-[16px] w-full text-[#1f1f1f]"
                v-show="activeAccordin === index"
                v-html="item.content"
              ></p>
            </div>
          </div>
          <!-- <div
            v-if="this.end < this.accordinItem.length"
            class="w-full md:flex md:justify-center md:items-center py-2"
          >
            <button
              @click="viewMore"
              class="w-[140px] bg-[#f8c300] border rounded-xl md:rounded-lg py-[6px] md:py-[8px] font-semibold"
            >
              Load More!
            </button>
          </div> -->
          <!-- <div v-else>
            <button
              v-if="this.end > 3"
              @click="viewLess"
              class="w-[140px] bg-[#f8c300] border rounded-xl md:rounded-lg py-[6px] md:py-[8px] font-semibold"
            >
              Load Less!
            </button>
          </div> -->
        </div>
      </main>
    </section>
  </template>
  
  <script>
  export default {
    name: "FaqView",
    props: ["faq"],
    data() {
      return {
        faqToShow: 6,
       
        activeAccordin: null,
        error: null,
        start: 0,
        end: 6,
      };
    },
    methods: {
      toggleAccordin(index) {
        this.activeAccordin = this.activeAccordin === index ? null : index;
      },
      viewMore() {
        this.end += 2;
      },
      viewLess() {
        this.end = 4;
      },
    },
    computed: {
      showMore() {
        return this.faqToShow < this.faq.length;
      },
      showLess() {
        return this.faqToShow > 4 && !this.showMore;
      },
    },
  };
  </script>
  
<style scoped>
.svg-inline--fa {
	display: var(--fa-display, inline-block);
	height: 1em;
}
</style>
  