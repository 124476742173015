<template>
  <div class="lg:py-20 px-5 lg:px-8">
    <div class="max-w-6xl mx-auto">
      <div class="lg:flex lg:justify-between">
        <div>
          <h2 class="text-[30px] lg:text-[60px] font-normal ">
            <span class="text-[#18479E] text-start">A Career in {{ Electives.name }}: </span> <br />
            <span class="text-[#EC027C] text-center">Who Should Go for It?</span>
          </h2>
        </div>
        <div class="w-[263px] hidden lg:block">
          <img
            src="@/assets/elective/element.svg"
            alt="element icon"
            class="w-[263px] h-[164px]"
          />
        </div>
      </div>

      <div v-for="who in whos" :key="who" class="flex flex-col gap-3 lg:gap-5 pt-3 lg:pt-4">
        <h2 class="text-[#18479E] text-[20px] lg:text-[30px] font-medium">{{ who.title }}</h2>
        <p class="text-[16px] lg:text-[28px] font-normal">{{ who.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhoShouldView",
  props: ["Electives"],
  data() {
    return {
      whos: [
        {
          id: 1,
          title: "Graduates",
          description:
            "Graduates who are interested in the field of logistics can receive great benefits from the course. The program equips students with the necessary skills and knowledge for roles in logistics, inventory management, import/export operations, procurement purchasing, and other related departments in the ever-evolving logistics industry.",
        },
        {
          id: 2,
          title: "Working Professionals",
          description:
            "This program is a game changer for working professionals and gives them a comprehensive understanding of the various aspects in the field of logistics. Whether one is looking at a salary increase, overall career growth or even a transition to logistics-based roles, this course will enable them to further succeed in this dynamic landscape.",
        },
        {
          id: 3,
          title: "Entrepreneurs",
          description:
            "With the know-how provided by this program, entrepreneurs will be further empowered to make informed decisions when it comes to optimising their business operations better. The expertise gained through this course will also provide entrepreneurs with a significant edge in the competitive logistics industry, ensuring that they always stay ahead of the curve.",
        },
      ],
    };
  },
  updated(){
    console.log(this.Electives)
  }
};
</script>

<style></style>
