<template>
  <div class="px-5 lg:px-8">
    <!-- program overview -->
    <div class="max-w-6xl mx-auto py-5 lg:py-10">
      <div>
        <h2 class="text-[30px] lg:text-[60px]">
          <span class="text-[#18479E]">Program </span>
          <span class="text-[#EC027C]">Overview</span>
        </h2>
        <p class="text-[16px] text-[#4D4D4D] lg:text-[28px]" v-html="elective">
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramOver",
  props: ["elective"],

};
</script>

<style></style>
