<template>
  <div class="px-5 lg:px-8">
    <div class="max-w-6xl mx-auto lg:py-20">
      <!-- desktop -->
      <div class="hidden lg:block">
        <div class="flex flex-col lg:flex-row gap-5 items-center">
          <div class="w-[400px]">
            <h2 class="text-[30px] lg:text-[40px] xl:text-[55px] font-normal leading-tight">
              <span class="text-[#18479E]">Get </span> <br />
              <span class="text-[#EC027C] whitespace-nowrap">Mentored By <br />
                India’s
              </span>
              <span class="text-[#18479E]">Best</span>
            </h2>

            <div class="pt-10 flex justify-start gap-4">
              <div
                class="border rounded-3xl w-[91px] h-[49px] p-3 prev-mentor flex items-center justify-center cursor-pointer"
                @mouseover="changeBgColorLeft" @mouseleave="resetBackgroundColorLeft" :style="{
                  backgroundColor: backgroundColorTWo,
                  borderColor: borderColorTwo,
                }">
                <img src="@/assets/home/getmentored/Icon feather-arrow-left@2x.png" alt="" srcset=""
                  class="w-[16px] h-[16px]" v-if="!toggleBgImgOne" />
                <img src="@/assets/home/getmentored/Icon feather-arrow-left@2xwhite.png" alt="" srcset=""
                  class="w-[16px] h-[16px]" v-else />
              </div>
              <div
                class="rounded-3xl p-3 w-[91px] h-[49px] border next-mentor flex items-center justify-center cursor-pointer"
                @mouseover="changeBgColor" @mouseleave="resetBackgroundColor" :style="{
                  backgroundColor: backgroundColor,
                  borderColor: borderColorOne,
                }">
                <img src="@/assets/home/getmentored/Icon feather-arrow-right.webp" alt="" srcset=""
                  class="w-[16px] h-[16px]" v-if="!toggleBgImgTwo" />

                <img src="@/assets/home/getmentored/Icon feather-arrow-right@2x.png" alt="" srcset=""
                  class="w-[16px] h-[16px]" v-else />
              </div>
            </div>
          </div>

          <div class="w-[70%]">
            <div class="flex justify-between slick-mentor" v-if="programfacultyData">
              <div v-for="mentor in programfacultyData" :key="mentor.id" class="xl:h-[500px]">
                <div class="">
                  <div class="cards relative">
                    <img src="@/assets/home/getmentored/bg element@2x.png" alt="border-background"
                      class="lg:w-[350px] lg:h-[355px] xl:w-[390px] xl:h-[430px] current flex gap-2" />

                    <img :src="mentor.image" alt=""
                      class="lg:w-[300px] lg:h-[360px] xl:w-[370px] xl:h-[450px] absolute top-5 file opacity-55 rounded-2xl" />

                    <!-- <img
                      src="@/assets/home/students/Group-27.svg"
                      alt="play"
                      class="absolute top-[12rem] left-[10rem] z-50 current bg-[#000000B1]"
                    />  -->
                    <div
                      class="bg-gradient-to-b from-[#00000000] to-[#000000]  absolute bottom-[-39px] lg:w-[300px]  xl:w-[370px] h-[250px]  left-0 z-30 current rounded-b-xl">
                    </div>
                    <div class="absolute bottom-4 left-7 w-[330px] z-30 current">
                      <h2 class="lg:text-[20px] font-semibold text-white py-2">
                        {{ mentor.name }}
                      </h2>
                      <div class="flex flex-col gap-3">
                        <div class="flex gap-3" v-if="mentor.department != ''">
                          <img src="@/assets/home/getmentored/Icon awesome-graduation-cap.svg" class="w-[20px] h-[20px]"
                            alt="" srcset="" />
                          <p class="text-[14px] text-white">
                            Niche area: {{ mentor.department }}
                          </p>
                        </div>
                        <div class="flex gap-3">
                          <img src="@/assets/home/getmentored/Working.webp" class="w-[20px] h-[20px]" alt="" srcset="" />
                          <p class="text-[14px] text-white">
                            {{ mentor.designation }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- mobile -->
      <div class="block lg:hidden">
        <h2 class="text-[30px] text-center font-normal leading-tight py-10">
          <span class="text-[#18479E]">Get </span>
          <span class="text-[#EC027C]">Mentored By <br />
            India's
          </span>
          <span class="text-[#18479E]">Best</span>
        </h2>
        <div>
          <div class="flex slick-mentor_mobile">
            <div v-for="mentor in programfacultyData" :key="mentor" class="">
              <div class="h-[400px]">
                <div class="cards relative w-[308px] mx-auto">
                  <div class="flex justify-end">
                    <img src="@/assets/home/getmentored/bg element@2x.png" alt="border-background"
                      class="w-[280px] h-[283px]" />
                  </div>
                  <img :src="mentor.image" alt=""
                    class="w-[290px] h-[340px] left-3 absolute top-5 rounded-lg bg-gradient-to-b from-[#00000000] to-[#000000]" />

                  <div
                    class="bg-gradient-to-b from-[#00000000] to-[#000000] absolute w-[290px] h-[340px] top-5 left-3 z-30 rounded-lg">
                  </div>
                  <div class="absolute bottom-[-55px] left-7 z-30">
                    <h2 class="text-[20px] font-semibold text-white py-2">
                      {{ mentor.name }}
                    </h2>
                    <div class="flex flex-col gap-3">
                      <div class="flex gap-3" v-if="mentor.department != ''">
                        <img src="@/assets/home/getmentored/Icon awesome-graduation-cap.svg" class="w-[20px] h-[20px]"
                          alt="" srcset="" />
                        <p class="text-[14px] text-white w-[15rem]">
                          Niche area: {{ mentor.department }}
                        </p>
                      </div>
                      <div class="flex gap-3">
                        <img src="@/assets/home/getmentored/Icon awesome-graduation-cap.svg" class="w-[20px] h-[20px]"
                          alt="" srcset="" />
                        <p class="text-[14px] text-white">
                          {{ mentor.designation }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-4 flex justify-center gap-4">
            <div class="border rounded-3xl w-[91px] h-[49px] p-3 prev-mentor_mobile flex items-center justify-center"
              @mouseover="changeBgColorLeft" @mouseleave="resetBackgroundColorLeft" :style="{
                backgroundColor: backgroundColorTWo,
                borderColor: borderColorTwo,
              }">
              <img src="@/assets/home/getmentored/Icon feather-arrow-left@2x.png" alt="" srcset=""
                class="w-[16px] h-[16px]" v-if="!toggleBgImgOne" />
              <img src="@/assets/home/getmentored/Icon feather-arrow-left@2xwhite.png" alt="" srcset=""
                class="w-[16px] h-[16px]" v-else />
            </div>
            <div class="rounded-3xl p-3 w-[91px] border h-[49px] next-mentor_mobile flex items-center justify-center"
              @mouseover="changeBgColor" @mouseleave="resetBackgroundColor" :style="{
                backgroundColor: backgroundColor,
                borderColor: borderColorOne,
              }">
              <img src="@/assets/home/getmentored/Icon feather-arrow-right.webp" alt="" srcset=""
                class="w-[16px] h-[16px]" v-if="!toggleBgImgTwo" />

              <img src="@/assets/home/getmentored/Icon feather-arrow-right@2x.png" alt="" srcset=""
                class="w-[16px] h-[16px]" v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
import axios from "axios";
export default {
  name: "GetMentoredView",
  props: ["faculty"],
  data() {
    return {
      backgroundColor: "",
      borderColorOne: "#4D4D4D",
      borderColorTwo: "#4D4D4D",
      backgroundColorTWo: "",
      programfacultyData: [],
      toggleBgImgOne: false,
      toggleBgImgTwo: false,
      
      newMentorData: [],
    };
  },
  watch: {
    faculty(newVal) {
      if (newVal) {
        this.programfacultyInfo();
      }
    }
  },

  mounted() {
    if (this.faculty) {
      this.programfacultyInfo()
    }
  },

  methods: {

    async programfacultyInfo() {

      try {
        const resp = await axios.get(`${process.env.VUE_APP_API}/course/program/faculty-list/?program__slug=${this.faculty.slug}`);
        if (resp.data.status === 200) {
          this.programfacultyData = resp.data.data;
          // console.log(this.programfacultyData, "Hello");

          setTimeout(() => {
            this.slickDes();
            this.slickMob();
          }, 200)
        }
      } catch (error) {
        console.log(error);
      }
    },
    slickDes() {

      $(".slick-mentor").slick({
        
        infinite: true,
        autoplay: true,
        speed: 200,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipeToSlide: true,
        leftMode: true,
        centerPadding: "0px",
        prevArrow: $(".prev-mentor"),
        nextArrow: $(".next-mentor"),
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });

    },
    slickMob() {
      $(".slick-mentor_mobile").slick({
        infinite: true,
        autoplay: true,
        speed: 200,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipeToSlide: true,
        prevArrow: $(".prev-mentor_mobile"),
        nextArrow: $(".next-mentor_mobile"),
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
    udatedMentors() {
      this.newMentorData = this.mentors.filter(
        (item) => item.porgram == this.faculty.program.slug
      );
    },
    changeBgColor() {
      this.backgroundColor = "#EC027C";
      this.toggleBgImgTwo = true;
      this.borderColorOne = "";
    },
    resetBackgroundColor() {
      this.backgroundColor = "";
      this.toggleBgImgTwo = false;
      this.borderColorOne = "#4D4D4D";
    },
    changeBgColorLeft() {
      this.backgroundColorTWo = "#EC027C";
      this.toggleBgImgOne = true;
      this.borderColorTwo = "";
    },
    resetBackgroundColorLeft() {
      this.backgroundColorTWo = "";
      this.toggleBgImgOne = false;
      this.borderColorTwo = "#4D4D4D";
    },
  },
};
</script>

<style>
.slick-mentor .slick-slide .current {
  opacity: 0;
}
 .slick-mentor .slick-current .file {
    opacity: 1;
  }
.slick-mentor .slick-current .current {
  opacity: 1;
}

.slick-mentor .slick-slide .leftmode {
  opacity: 0;
  background-color: #000000b1;
}

.slick-mentor .slick-current .leftmode {
  opacity: 0.3;
  background-color: #000000b1;
  border-radius: 1rem;
}
</style>
