<template>
  <section class="w-full my-2 lg:my-24">
    <div class="max-w-5xl mx-auto px-5">
      <div class="flex flex-wrap-reverse lg:flex-nowrap justify-between">
        <h2
          class="mt-4 text-[#18479E] leading-tight text-[60px] hidden lg:block self-end pb-4"
        >
          Awards & <span class="text-[#ec027c]">Accolades</span>
        </h2>
        <h2 class="mt-4 text-[#18479E] text-[30px] leading-tight lg:hidden m-auto">
          Awards & Accolades
        </h2>

        <div class="w-[300px] hidden lg:block">
          <img
            src="@/assets/about/elementAbout.svg"
            alt="element icon"
            class="w-[300px] h-[200px]"
          />
        </div>
      </div>
      <div class="mt-5">
        <div class="flex flex-col gap-5 lg:gap-0">
          <div
            class="p-10 bg-[#18479E] flex-col-reverse items-center flex lg:flex-row justify-between gap-2 rounded-lg"
          >
            <div>
              <h2
                class="text-[#0CB1EF] text-[20px] lg:text-5xl font-medium text-center lg:text-left"
              >
                <span class="text-white">NIRF University</span> Rank 86
              </h2>
            </div>
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/about/rank.png"
              alt="element icon"
            />
          </div>
          <div
            class="p-10 bg-white flex-col items-center flex lg:flex-row lg:justify-between gap-2 rounded-lg border"
          >
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/about/accred.png"
              alt="element icon"
            />
            <div>
              <h2
                class="text-[#18479E] text-[20px] lg:text-5xl font-medium text-center whitespace-nowrap lg:text-right"
              >
                A+ Grade accreditation by NAAC
              </h2>
            </div>
          </div>
          <div
            class="p-10 bg-[#18479E] flex-col-reverse items-center flex lg:flex-row justify-between gap-2 rounded-lg"
          >
            <div>
              <h2
                class="text-[#0CB1EF] text-[20px] lg:text-5xl font-medium text-center lg:text-left"
              >
                ARIIA <br class="lg:hidden block">
                <span class="text-white whitespace-nowrap">Certification Of Recognition</span>
              </h2>
            </div>
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/about/certification.png"
              alt="element icon"
            />
          </div>
          <div
            class="p-10 bg-white flex-col items-center flex lg:flex-row justify-between gap-2 rounded-lg border"
          >
            <img
              class="w-[150px] h-[150px]"
              src="@/assets/about/certificationTwo.png"
              alt="element icon"
            />
            <div>
              <h2
                class="text-[#18479E] text-[20px] lg:text-5xl font-medium text-center whitespace-nowrap lg:text-right"
              >
                QS I.GAUGE E-LEAD Certificate
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "awadeVue",
  data() {
    return {};
  },
};
</script>
