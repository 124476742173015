<template>
  <div>
    <headerView />
    <!--banner-->
    <div class="bg-gradient-to-b from-[#FFFFFF] to-[#EFEFEF]">
      <div class="max-w-7xl m-auto">
        <div
          class="flex flex-col justify-center lg:justify-around items-center mt-[6rem] mb-[2rem] lg:flex-row-reverse lg:py-[4rem]"
        >
          <div class="w-[15rem] md:w-[18rem] lg:w-[25rem] xl:w-[30rem]">
            <img src="@/assets/shore/banner.png" alt="banner" />
          </div>
          <div class="md:mr-12">
            <div
              class="text-3xl lg:text-5xl xl:text-6xl w-[13rem] lg:w-[22rem] xl:w-[27rem] my-4 text-[#18479E]"
            >
              Sharda Online <span class="text-[#EC027C]">Recreation</span> And
              <span class="text-[#EC027C]">Engagement</span>
            </div>
            <div class="text-lg lg:text-3xl w-[14rem] lg:w-[18rem] pb-4">
              Where Learners Meet Endless Possibilities…
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--banner-->
    <!--what is shore-->
    <div class="max-w-7xl m-auto">
      <div class="ml-8 flex flex-col items-center">
        <div
          class="text-[#18479E] text-3xl lg:text-6xl lg:py-6 mr-[4.5rem] lg:self-start"
        >
          What is <span class="text-[#EC027C]">SHORE?</span>
        </div>
        <div
          class="w-[18rem] text-start lg:self-start lg:w-[55rem] xl:w-[65rem] 2xl:w-[68rem] lg:text-3xl lg:leading-10"
        >
          Welcome to SHORE, where we redefine online learning by creating a
          vibrant and engaging educational experience that goes “Beyond
          Boundaries.” At Sharda Online, we understand that online learning
          offers flexibility and convenience, but we also recognize the
          challenges it presents in terms of engagement and community building.
          That’s where SHORE comes in.
        </div>
      </div>
    </div>
    <!--what is shore-->
    <shoreEntailVue />
    <expectionView />
    <!-- <upcomingEvent /> -->
    <footerView />
    <stickyView />
  </div>
</template>

<script>
import headerView from "@/components/common/header.vue";
import footerView from "@/components/common/footer.vue";
import stickyView from "@/components/common/sticky.vue";
import shoreEntailVue from "../components/shore/shoreEntail.vue";
import expectionView from "@/components/shore/expectionMeet.vue";
// import upcomingEvent from "@/components/shore/upcomingEvent.vue";
export default {
  name: "shoreView",
  components: {
    headerView,
    footerView,
    stickyView,
    shoreEntailVue,
    expectionView,
    // upcomingEvent,
  },
};
</script>

<style scoped></style>
