<template>
  <div>
    <section class="w-full justify-center my-20 hidden lg:flex">
      <!--desktop-->
      <div class="max-w-6xl mx-auto w-full px-5">
        <div class="flex justify-center">
          <h2
            class="text-[#18479E] text-center text-[30px] lg:text-[60px] mb-10 w-[70%] lg:w-[100%]"
          >
            <span class="text-[#ec027c]">Knowledge</span> &
            <span class="text-[#ec027c]">Insights</span> for You
          </h2>
        </div>
        <div class="CarouselDesktop">
          <div v-for="blog in blogs" :key="blog.id" class="w-max m-auto">
            <div class="flex flex-col lg:flex-row items-center gap-5 lg:gap-10">
              <div>
                <img
                  class="w-[420px] h-[350px] lg:h-[460px] object-cover"
                  :src="blog.image"
                  alt="Blog Image"
                />
              </div>
              <div class="flex flex-col gap-4">
                <div class="text-[18px] lg:text-[28px] lg:w-[25rem]">
                  <a
                  :href="`/blogs/${blog.slug}`"
                  class=""
                  >
                  {{ blog.title }}
                  </a>
                </div>
                <div class="text-[14px] text-[#4d4d4d]">
                  APN News | {{ formatDate(blog.publish_at) }}
                </div>
                <!-- <a
                  :href="`/blogs/${blog.slug}`"
                  class="text-[16px] text-[#ec027c] font-medium"
                  >Read More</a
                > -->
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-10">
          <a href="/blogs" class="text-[#ec027c] font-medium text-[18px]"
            >View All blogs</a
          >
        </div>
      </div>
    </section>
    <!--desktop-->
    <!--mobile-->
    <div class="lg:hidden my-10">
      <div class="text-3xl text-center mb-5 text-[#EC027C]">
        Knowledge <span class="text-[#18479E]"> &</span> <br />
        Insights <span class="text-[#18479E]">for You</span>
      </div>
      <div class="Carousel ssm:ml-0 ml-[1rem] md:ml-[13rem]">
        <div
          v-for="blog in blogs"
          :key="blog.id"
          class="ssm:w-[18rem] w-[20rem] m-auto"
        >
          <div
            class="flex flex-col lg:flex-row  items-center gap-5 lg:gap-10 ssm:w-[18rem] w-[20rem]"
          >
            <div>
              <img
                class="ssm:w-[18rem] w-[20rem] h-[350px] ] object-cover"
                :src="blog.image"
                alt="Blog Image"
              />
            </div>
            <div class="flex flex-col gap-4">
              <div class="text-[18px] lg:text-[28px] lg:w-[25rem]">
                <a
                  :href="`/blogs/${blog.slug}`"
                  class=""
                  >
                {{ blog.title }}
                </a>
              </div>
              <div class="text-[14px] text-[#4d4d4d]">
                APN News | {{ formatDate(blog.publish_at) }}
              </div>
              <!-- <a
                :href="`/blogs/${blog.slug}`"
                class="text-[16px] text-[#ec027c] font-medium"
                >Read More</a
              > -->
            </div>
          </div>
        </div>
      </div>
      <a href="/blogs"
        ><div class="text-[#EC027C] w-max m-auto my-4">View All Blogs</div></a
      >
    </div>
  </div>
  <!--mobile-->
</template>

<script>
import axios from "axios";
import $ from "jquery";
import "slick-carousel";
export default {
  data() {
    return {
      blogs: [],
    };
  },
  async created() {
    await this.fetchBlogs();
    this.blogsCarousel();
    this.blogsCarouselDesktop();
  },
  methods: {
    async fetchBlogs() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API}/blog/blog-list/`
        );
        if (response.data.status == 200) {
          this.blogs = response.data.data;
        }
        console.log("Blogs:", this.blogs);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { month: "long", day: "2-digit", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },
    blogsCarousel() {
      $(`.Carousel`).slick({
        infinite: true,
        autoplay: false,
        prevArrow: null,
        nextArrow: null,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      });
    },
    blogsCarouselDesktop() {
      $(`.CarouselDesktop`).slick({
        infinite: true,
        autoplay: true,
        prevArrow: null,
        nextArrow: null,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      });
    },
  },
};
</script>

<style>
/* Add your styles here if needed */
</style>
