<template>
    <div class="px-5 lg:px-8">
        <div class="max-w-7xl mx-auto my-20 lg:my-20">
            <div class="flex flex-wrap-reverse lg:flex-nowrap gap-9 justify-center lg:justify-left">
                <div class="flex flex-col gap-4 justify-center items-center lg:items-start">
                    <h1 class="text-[30px] lg:text-[40px] xl:text-[60px] leading-tight font-normal">
                        <span class="text-[#18479E]">Who </span>
                        <span class="text-[#EC027C]"> can apply</span>
                    </h1>
                    <h2 class="text-[20px] lg:text-[30px] text-[#EC027C] font-medium lg:font-semibold">
                        Undergraduate (Bachelors) Program 
                    </h2>
                    <p class="text-[16px] lg:text-[24px] lg:w-[600px]">
                        Candidates must have passed the (10+2) examination from a State Board / CBSE / NIOS / IGCSE / IB / ICSE recognized by the State or Central Government. 
                    </p>
                    <h2 class="text-[20px] lg:text-[30px] text-[#EC027C] font-medium lg:font-semibold">
                        Postgraduate (Masters) Program 
                    </h2>
                    <p class="text-[16px] lg:text-[24px] lg:w-[600px]">
                        Pass in an Undergraduate (Bachelor) Program of a minimum duration of three (3) years in any stream from a UGC recognized University, with a minimum aggregate of 45% or an equivalent letter/numerical grade. 
                    </p>
                    <a href="/#enquire">
                        <button class="h-[50px] w-[160px] bg-[#EC027C] text-white lg:text-[20px] font-bold rounded-[10px]">
                            Enquire now
                        </button>
                    </a>
                </div>
                <div class="flex items-end">
                    <div class="w-[5rem] lg:w-[8rem]">
                        <img src="@/assets/home/header/bannerPatelDesk.webp" alt="bannerPattel" />
                    </div>
                    <div class="w-full lg:w-[450px] xl:w-[515px]">
                        <img src="@/assets/lpProgram/who-can-apply.webp" alt="" srcset=""
                            class="w-full h-[280px] lg:w-[450px]  xl:w-[515px] lg:h-[481px]" />
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "WhoapplyView",
};
</script>
  
<style></style>
  