<template>
  <div class="h-screen">
    <headerView />
    <div class="mx-auto max-w-6xl">
      <section class="w-full max-w-7xl mx-auto px-5 pt-[120px]">
        <!-- Academic Resources -->
        <div class="text-3xl md:text-5xl font-medium w-max text-[#18479E]">
          Academic <span class="text-[#EC027C]">Resources</span>
        </div>

        <div class="text-left flex flex-col gap-3 py-3 lg:py-6">
          <!-- <h3 class="text-[12px] lg:text-[15px] text-[#000000] font-bold px-3" >
            Compliance status of Regulations UGC (ODL and Online Programs) Regulations - Self-regulation through disclosures, declarations and reports
          </h3> -->
          <div class="flex flex-col gap-3 bg-[#F6F8FB] overflow-x-auto">
            <table class="border-separate border-spacing-3">
              <thead>
                <tr class="w-full md:text-xl md:leading-[1.5rem] md:font-base text-white">
                  <th class="bg-[#18479E] px-5 py-2.5">S.No.</th>
                  <th class="bg-[#18479E] px-5 py-2.5">Information</th>
                  <th class="bg-[#18479E] px-5 py-2.5">Details</th>
                </tr>
              </thead>
              <tbody class="mt-2 md:text-xl md:leading-[1.5rem] md:font-base">
                <tr class="w-full text-black bg-white">
                  <td class="text-left py-2 px-5">1.</td>
                  <td class="w-1/2 text-left py-2 px-5">
                    Important dates and events for the batch of July 2024
                  </td>
                  <td class="w-1/2 text-left py-2 px-5">
                    <a href="https://api.shardaonline.online/media/TENTATIVE-SCHEDULE_20035.pdf" target="_blank"
                      class="text-[#18479E]">Click Here</a>
                  </td>
                </tr>
                <tr class="w-full text-black bg-white">
                  <td class="text-left py-2 px-5">2.</td>
                  <td class="w-1/2 text-left py-2 px-5">
                    Sample copy of e-Learning material
                  </td>
                  <td class="w-1/2 text-left py-2 px-5 text-[#18479E]">
                    <a href="https://api.shardaonline.online/media/Digital-Electronics-and-Computer-Organization-SLM_93770.pdf"
                      target="_blank">Click Here</a>
                  </td>
                </tr>
                <tr class="w-full text-black bg-white">
                  <td class="text-left py-2 px-5">3.</td>
                  <td class="w-1/2 text-left py-2 px-5">
                    Sample of Asynchronous Video
                  </td>
                  <td class="w-1/2 text-left py-2 px-5 text-[#18479E] cursor-pointer" @click="openmodal()">
                    Watch Now
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal" id="shardavideo">
          <div class="modal-content1">
            <div class="flex justify-center gap-2">
              <div class="flex justify-center items-center z-10 cursor-pointer">
                <div class="border border-[#18479E] rounded-full p-3 prev-aff slick-arrow" style="">
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAjCAYAAADxG9hnAAAABHNCSVQICAgIfAhkiAAAATVJREFUWEfF2DsOgkAQBuAZTmAn3sXCTm6hq73xKh4AJfEClmJhTGw8gqWFUUq9ACtgNCoq+2BnKAjFzvDtFswfECwvPwj7XupdTiuxsGmFNsV+MI1AQi/rcU3iQcOmlzHkBQGAUiTLYUQOqRuRb0D7RFwgtCGuEFoQlwhliGuEEoQCUQmhQvyFUCJ+QqgRXyEciBKEC/EG4UQ8IdyIAtLqhlsJ2C4mJ8I6u+1tpqhZbXpAvzuVZsW1VqXYDMIJShwVbSXsANJ5ra9QaCY9PBYxII97IHF2r8EoiYVQqK91yTOPcGPeghEnppTQuDBfoyIH5mdmpcb8Dc+UmMoUT4WphFB9Z5QgFBhliGuMFsQlRhviCmME+cRkTSbneDC2mYLGkA8M3/+Rx+79YNbJn5Ol2NicyA3Zwd3almBqtgAAAABJRU5ErkJggg==" alt="" srcset="" class="w-[16px] h-[16px]">
                </div>
              </div>
              <div class="slick-watch w-[270px] md:w-[420px]">
                <div v-for="(video, index) in videos" :key="index">
                  <div class="" v-if="video.showThumbnail" @click="playVideo(index)">
                    <img :src="video.image" alt="" srcset="" class="w-full h-[220px] md:h-[300px]" />
                  </div>
                  <iframe v-else @click="stopVideo(index)" class="w-full md:w-[400px] h-[220px] md:h-[300px]"
                    allow="accelerometer;autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen="" :src="video.link" title="video player" frameborder="0"></iframe>

                </div>
              </div>
              <div class="flex justify-center items-center z-10 cursor-pointer">
                <div class="border border-[#18479E] rounded-full p-3 next-aff slick-arrow" style="">
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAjCAYAAADxG9hnAAAABHNCSVQICAgIfAhkiAAAAThJREFUWEfF2DFuwkAQBdAZX4ASJ6JIyREoUYrgCnEBBIYDpMo9UHps5xaEioLDuOQCeLK2owgJAd6Z2ck2djNfT6uV/moRhOv5LZ9VQL1yv/qSRKFkuJ6NJ9nJfXoAWJTfacrNk0OS7RII8xbAx4ghza4oYFQgGhg1iBSjCpFg1CFcTBAIBxMM4osJCvHBBId0xZhAumDMII8wppB7GHPILQy6wvoAiF649c2foyEQvDadDXREd584u/+IH6gzif1kO8WKBjpxHilRNHc7MqonCOnzn85IVjjEor1LUVru1oU5JE6uEe05MVy3EKaQewgzyCOECaQLIjikKyIoxAcRDOKLCALhINQhXIQqRIJQg0gRKpCnSbYhgPfLAuO0hrhr/t5HfluUg1DZkTjJx3VQuUsPXEQ99wPxxNpto0Wy/QAAAABJRU5ErkJggg==" alt="" srcset="" class="w-[16px] h-[16px]">
                </div>
              </div>
              <span class="close text-white" @click="closeModal">&times;</span>
            </div>
          </div>
        </div>
      </section>
    </div>

    <footerView />
    <stickyView />
  </div>
</template>
  
<script>
import $ from "jquery";
import "slick-carousel";
import headerView from "@/components/common/header.vue";
import footerView from "@/components/common/footer.vue";
import stickyView from "@/components/common/sticky.vue";
export default {
  name: "AcademicResources",
  components: {
    headerView,
    footerView,
    stickyView,
  },

  data() {
    return {
      leaders: "",

      videos: [
        {
          showThumbnail: true,
          image: require("@/assets/resource/image.png"),
          link: "https://api.shardaonline.online/media/Shubh_Arora_Principles_of_Management.mp4",
        },
        {
          showThumbnail: true,
          image: require("@/assets/resource/image1.png"),
          link: "https://api.shardaonline.online/media/Madhu_Verma_Madhu_Verma_sample.mp4",
        },
      ],
    };
  },

  methods: {
    openmodal() {
      const modal = document.getElementById("shardavideo");
      modal.style.display = "block";
      this.intilialize();
    },

    closeModal() {
      const modal = document.getElementById("shardavideo");
      modal.style.display = "none";

      window.location.reload();
    },
    intilialize() {
      $(".slick-watch").slick({
        infinite: true,
        autoplay: false,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $(".prev-aff"),
        nextArrow: $(".next-aff"),
        // autoplaySpeed: 3000,
      });
    },
    playVideo(index) {
      this.videos.forEach((video, i) => {
        if (i === index) {
          video.showThumbnail = false;
        } else {
          video.showThumbnail = true;
        }
      });
    },
    stopVideo(index) {
      this.videos[index].showThumbnail = true;
    },
  },

};
</script>
  
<style scoped>
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.3);
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px 20px 10px 20px;
  border: 1px solid #888;
  width: 50%;
  height: 90%;
  overflow: scroll;
}
@media (max-width: 767.98px) {
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 10px 10px 50px 10px;
    border: 1px solid #888;
    width: 100%;
}
}

/* The Close Button */
.close {
  color: #fff;
  float: right;
  font-size: 28px;
  font-weight: bold;
  width: 1rem;
  height: 2.4rem;
}
.close:hover,
.close:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

</style>
  