<template>
  <div class="max-w-7xl m-auto py-3">
    <div
      class="ssm:pl-4 pl-8 text-[#EC027C] text-3xl lg:text-6xl lg:m-auto lg:w-max"
    >
      <span class="text-[#18479E]">Common</span> Enquiries
    </div>
    <!-- Mob design -->
    <div class="lg:hidden">
      <div class="overflow-y-scroll" id="scrolls">
        <div v-for="tab in filterFaq" :key="tab.id" class="my-8">
          <div
            @click="toggleMainTab(tab.id), faqList(tab.id)"
            :class="{ active: tab.id === selectedMainTabId }"
            class="cursor-pointer flex justify-between px-8"
          >
            <div
              :class="
                tab.id === selectedMainTabId
                  ? 'text-[#EC027C] text-[18px] font-bold'
                  : 'text-black text-[18px]'
              "
            >
              {{ tab.title }}
            </div>

            <div>
              <img src="@/assets/home/header/downarrow.png" alt="downarrow" />
            </div>
          </div>
          <div v-if="selectedMainTab" class="main-tab">
            <div v-show="tab.id === selectedMainTabId" class="main-tab-content">
              <div
                v-for="mainTab in faqlist"
                :key="mainTab.id"
                class="mb-[10px]"
              >
                <div
                  class="px-6"
                  :class="
                    tab.id === selectedMainTabId
                      ? 'h-[.15rem]  m-auto bg-[#18479E] opacity-20 mt-8 w-[85%]'
                      : 'text-black text-[18px]'
                  "
                ></div>
                <div
                  class="cursor-pointer flex justify-between item-center px-8"
                  @click="selectInnerTab(mainTab.id)"
                  :class="{ active: mainTab.id === selectedInnerTabId }"
                >
                  <button
                    class="cursor-pointer py-[5px] px-[10px] text-left"
                    :class="
                      mainTab.id === selectedInnerTabId
                        ? 'text-[#18479E] text-[18px]'
                        : 'text-blue'
                    "
                  >
                    {{ mainTab.title }}
                  </button>
                  <div
                    class="flex items-center"
                    v-if="mainTab.id === selectedInnerTabId"
                  >
                    <img src="@/assets/home/header/minus.svg" alt="minus" />
                  </div>
                  <div class="flex items-center" v-else>
                    <img src="@/assets/home/header/plus.svg" alt="plus" />
                  </div>
                </div>
                <div
                  v-if="mainTab.id === selectedInnerTabId"
                  class="mt-[10px] m-auto"
                >
                  <p class="px-10 text-[15px]" v-html="mainTab.content"></p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="px-6"
            :class="
              tab.id === selectedMainTabId
                ? 'h-[.15rem]  m-auto bg-[#EC027C] opacity-20 mt-8 w-[85%]'
                : 'h-[.15rem]  m-auto bg-[#D1D1D1] opacity-20 mt-8 w-[85%]'
            "
          ></div>
        </div>

        <!-- <div
        class="text-[#EC027C] text-[16px] font-medium pl-4"
        @click="viewAll()"
      >
        {{ viewStatus ? "View Less" : "View More" }}
      </div> -->
      </div>
    </div>
    <!-- Mob design -->
    <!--desktop design-->
    <div class="hidden lg:block">
      <div class="flex justify-around items-start my-8">
        <div
          class="w-[50%] h-[500px] overflow-y-scroll scroll-smooth"
          id="my-scrollbar"
        >
          <div class="my-8" v-for="tab in filterFaq" :key="tab.id">
            <div
              @click="toggleMainTab(tab.id), faqList(tab.id)"
              :class="{ active: tab.id === selectedMainTabId }"
            >
              <div class="cursor-pointer flex items-center px-12">
                <div
                  :class="
                    tab.id === selectedMainTabId
                      ? 'text-[#EC027C] font-semibold'
                      : 'text-[#4D4D4D] font-normal'
                  "
                  class="text-xl text-start"
                >
                  {{ tab.title }}
                </div>
                <div v-if="tab.id === selectedMainTabId" class="ml-auto">
                  <img
                    src="@/assets/home/header/right_arrow.png"
                    alt="rightarrow"
                  />
                </div>
              </div>
              <div
                class="px-12"
                :class="
                  tab.id === selectedMainTabId
                    ? 'h-[.15rem]  m-auto bg-[#EC027C] opacity-20 mt-8 w-[85%]'
                    : 'h-[.15rem]  m-auto bg-[#D1D1D1] opacity-20 mt-8 w-[85%]'
                "
              ></div>
            </div>
          </div>
          <!-- <div
            class="ml-12 text-[#EC027C] cursor-pointer text-[16px] font-medium"
            @click="viewAll()"
          >
            {{ viewStatus ? "View Less" : "View More" }}
          </div> -->
        </div>

        <div
          class="w-[50%] mt-8 px-4 xl:px-3 h-[500px] overflow-y-scroll"
          id="mys-scrollbar"
        >
          <div v-if="activeTabData">
            <div
              v-for="item in faqlist"
              :key="item.id"
              class="pb-4 mb-4 border-b-[1px] border-[#D1D1D1]"
            >
              <div
                class="flex justify-between items-center mb-8 cursor-pointer"
                @click="toggleTab(item.id)"
              >
                <div class="text-lg text-[#18479E]">{{ item.title }}</div>
                <div v-if="activeContentId != item.id">
                  <img src="@/assets/home/header/plus.svg" alt="plus" />
                </div>
                <div v-else>
                  <img src="@/assets/home/header/minus.svg" alt="minus" />
                </div>
              </div>
              <div
                class="ml-4"
                v-show="item.id === activeContentId"
                v-html="item.content"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--desktop design-->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "enquireView",
  props:["electiveId"],
  data() {
    return {
      activeTab: 1,
      activeContentId: null,
      viewStatus: false,
      faqs: [],
      faqlist: [],
      // tabsData: [
      //   {
      //     id: 1,
      //     name: "About Online BCA",
      //     mainTab: [
      //       {
      //         title: "1. Why choose Sharda Online?",
      //         content: "lorem",
      //         id: 1,
      //       },
      //       {
      //         title:
      //           "2. What types of online degree programs are offered by Sharda Online?",
      //         content: "lorem",
      //         id: 2,
      //       },
      //       {
      //         title:
      //           "3. How do I enroll in an online program at Sharda Online?",
      //         content:
      //           "To enroll in an online program, visit our website and navigate to the admissions section. Follow the instructions for online application submission, and our admissions team will guide you through the process",
      //         id: 3,
      //       },
      //     ],
      //   },
      //   {
      //     id: 2,
      //     name: "About University",
      //     mainTab: [
      //       {
      //         title: "1. Why choose Sharda University?",
      //         content: "lorem",
      //         id: 1,
      //       },
      //       {
      //         title:
      //           "2. What types of online degree programs are offered by Sharda Online?",
      //         content: "lorem",
      //         id: 2,
      //       },
      //       {
      //         title:
      //           "3. How do I enroll in an online program at Sharda Online?",
      //         content:
      //           "To enroll in an online program, visit our website and navigate to the admissions section. Follow the instructions for online application submission, and our admissions team will guide you through the process",
      //         id: 3,
      //       },
      //     ],
      //   },
      //   {
      //     id: 3,
      //     name: "Features",
      //     mainTab: [
      //       {
      //         title: "1. Features",
      //         content: "lorem",
      //         id: 1,
      //       },
      //       {
      //         title:
      //           "2. What types of online degree programs are offered by Sharda Online?",
      //         content: "lorem",
      //         id: 2,
      //       },
      //       {
      //         title:
      //           "3. How do I enroll in an online program at Sharda Online?",
      //         content:
      //           "To enroll in an online program, visit our website and navigate to the admissions section. Follow the instructions for online application submission, and our admissions team will guide you through the process",
      //         id: 3,
      //       },
      //     ],
      //   },
      //   {
      //     id: 4,
      //     name: "Accreditations / Recognitions",
      //     mainTab: [
      //       {
      //         title: "1. Accreditations / Recognitions",
      //         content: "lorem",
      //         id: 1,
      //       },
      //       {
      //         title:
      //           "2. What types of online degree programs are offered by Sharda Online?",
      //         content: "lorem",
      //         id: 2,
      //       },
      //       {
      //         title:
      //           "3. How do I enroll in an online program at Sharda Online?",
      //         content:
      //           "To enroll in an online program, visit our website and navigate to the admissions section. Follow the instructions for online application submission, and our admissions team will guide you through the process",
      //         id: 3,
      //       },
      //     ],
      //   },
      //   {
      //     id: 5,
      //     name: "Examination",
      //     mainTab: [
      //       {
      //         title: "1. Examination",
      //         content: "lorem",
      //         id: 1,
      //       },
      //       {
      //         title:
      //           "2. What types of online degree programs are offered by Sharda Online?",
      //         content: "lorem",
      //         id: 2,
      //       },
      //       {
      //         title:
      //           "3. How do I enroll in an online program at Sharda Online?",
      //         content:
      //           "To enroll in an online program, visit our website and navigate to the admissions section. Follow the instructions for online application submission, and our admissions team will guide you through the process",
      //         id: 3,
      //       },
      //     ],
      //   },

      // ],
      selectedMainTabId: 1,
      selectedInnerTabId: null,
      newTabId: 1,
      resultTab: [],
      verifyInnerTab: false,
      filterFaq: [],
    };
  },
  // mounted() {
  //   var Scrollbar = window.Scrollbar;
  //   var options = {
  //     damping: 0.1,
  //     thumbMinSize: 20,
  //     renderByPixels: true,
  //     alwaysShowTracks: false,
  //     continuousScrolling: true,
  //   };
  //   var Scrollbars = window.Scrollbar;

  //   Scrollbar.init(document.querySelector("#my-scrollbar"), options);
  //   Scrollbar.init(document.querySelector("#scrolls"), options);
  //   Scrollbars.init(document.querySelector("#mys-scrollbar"), options);

  //   document
  //     .querySelector("#my-scrollbar")
  //     .addEventListener("mouseover", () => {
  //       document.querySelector("html, body").classList.add("stop-scrolling");
  //     });

  //   document
  //     .querySelector("#my-scrollbar")
  //     .addEventListener("mouseleave", () => {
  //       document.querySelector("html, body").classList.remove("stop-scrolling");
  //     });
  // },
  created() {
    // this.showInnerTab(this.newTabId);
    this.fetchFaq();
    // console.log(this.programm,"programm");
  },
  // computed(){
  //   newFaq(){
  //     this.viewStatus ? this.faqs : this.faqs.slice(0, 4);
  //   }
  // }
  methods: {
    async fetchFaq() {
      await axios
        .get(`${process.env.VUE_APP_API}/faq/elective-faq-category-list/`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.faqs = resp.data.data;
            this.filterFaq = this.faqs;
            if (this.faqs.length > 0) {
              this.toggleMainTab(this.faqs[0].id);
              this.selectedMainTabId = this.faqs[0].id
              this.faqList(this.faqs[0].id);
            }
            console.log(this.faqs, "faq");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async faqList(id) {
      await axios
        .get(`${process.env.VUE_APP_API}/faq/elective-faq-list/?category__id=${id}&elective__id=${this.electiveId}`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.faqlist = resp.data.data;
            this.activeContentId = resp.data.data[0].id;
            this.selectedInnerTabId = resp.data.data[0].id;
            console.log(this.faqlist, "faqlist");
          } else {
            this.faqlist = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleMainTab(id) {
      if (this.selectedMainTabId === id) {
        this.selectedMainTabId = null; // Close tab if already open
      } else {
        this.selectedMainTabId = id; // Open tab if closed
        this.selectedInnerTabId = null; // Reset inner tab selection
      }
      this.activeTab = id;
    },
    viewAll() {
      this.viewStatus = !this.viewStatus;
      this.filterFaq = this.viewStatus ? this.faqs : this.faqs.slice(0, 4);
      if (this.filterFaq.length > 0) {
        this.toggleMainTab(this.filterFaq[0].id);
        this.faqList(this.filterFaq[0].id);
      }
    },
    selectInnerTab(id) {
      this.selectedInnerTabId = this.selectedInnerTabId === id ? null : id;
    },
    showInnerTab(id) {
      this.resultTab = this.faqs.filter((item) => {
        return item.id == id;
      });
      this.verifyInnerTab = true;
    },
    toggleTab(contentId) {
      this.activeContentId =
        this.activeContentId === contentId ? null : contentId;
    },
  },
  computed: {
    activeTabData() {
      return this.faqs.find((tab) => tab.id === this.activeTab);
    },
    selectedMainTab() {
      return this.faqs.find((tab) => tab.id === this.selectedMainTabId);
    },
    selectedInnerTab() {
      if (this.selectedMainTab) {
        return this.selectedMainTab.mainTab.find(
          (tab) => tab.id === this.selectedInnerTabId
        );
      }
      return null;
    },
  },
};
</script>

<style scoped>
.main-tab-content {
  margin-top: 10px;
}

.main-tab-titles {
  display: flex;
  gap: 10px;
}
</style>
