<template>
  <div>
    <div class="m-auto w-max text-3xl lg:text-5xl lg:font-medium text-[#18479E]">
      Our <span class="text-[#EC027C]">Revered</span> Leaders
    </div>
    <!-- Mobile design -->
    <div class="my-2 lg:my-8 relative md:hidden">
      <div class="flex flex-col">
        <div class="relative cursor-pointer" v-for="el in contentData" :key="el.id">
          <div @click="selectedTab(el.id)" class="relative ssm:w-[16rem] w-[20rem] mx-auto">
            <div class="ssm:w-[16rem] w-[20rem] m-auto pb-6">
              <img :src="el.img" :alt="el.name" class="rounded-lg bg-gradient-to-b from-white to-black grayscale-[20%]" />
              <div
                class="bg-gradient-to-b from-[#00000000] to-[#000000] min-w-[20rem] md:left-[200px] h-[200px] bottom-6 absolute z-30 rounded-lg">
              </div>
            </div>

            <div class="absolute text-white bottom-[25%] left-[10%] md:left-[30%] font-bold ssm:text-sm text-lg z-40">
              {{ el.name }}
            </div>
            <div
              class="absolute text-white bottom-[8%] left-[10%] md:left-[30%] font-bold ssm:text-sm text-lg h-[3.5rem] w-[16rem] z-40">
              {{ el.about }}
            </div>
          </div>
          <!-- Tab to show mobile -->
          <div v-show="showLeaderTab">
            <!-- Add the overlay with reduced opacity -->
            <div class="fixed inset-0 bg-gray-300 bg-opacity-20 z-40" @click="showLeaderTab = false"></div>

            <div class="bg-white absolute top-0 ssm:right-[2%] right-[7%] md:right-[28%] shadow z-50">
              <div class="ssm:w-[19rem] w-[20rem] mx-auto">
                <div v-if="el.id == activeId" class="ssm:h-[40rem] h-[auto]">
                  <div class="relative">
                    <img src="@/assets/home/getmentored/bg element.png" alt="bgElement" class="w-[15rem] ml-auto" />
                    <img :src="el.img" :alt="el.name"
                      class="rounded-lg ssm:w-[17rem] w-[20rem] absolute z-10 top-[2%] right-[2%]" />
                  </div>
                  <div class="ssm:w-[18rem] w-[19rem] m-auto pt-8">
                    <div>
                      <p class="text-xl font-medium" :class="[isExpanded ? '' : 'line-clamp-3']">
                        {{ el.description }}
                      </p>
                      <button @click="toggleReadMore" class="mt-2 border-b-2 border-b-[#F8C300]">
                        {{ isExpanded ? "Read less" : "Read more" }}
                      </button>
                    </div>
                  </div>
                  <div class="text-xl text-[#18479E] font-bold ml-6">
                    {{ el.name }}
                  </div>
                  <div class="text-xs text-[#EC027C] font-light ml-6">
                    {{ el.about }}
                  </div>
                  <div class="m-auto w-max pt-4" @click="showLeaderTab = false">
                    <img src="@/assets/about/close.svg" alt="cross_black" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Tab to show mobile -->
        </div>
      </div>
    </div>
    <!-- Desktop design -->
    <div class="relative max-w-7xl m-auto hidden md:block my-4">
      <div class="flex flex-wrap gap-8 justify-center cursor-pointer lg:p-4 xl:p-0 relative">
        <div v-for="el in contentData" :key="el.id">
          <div @click="selectedTabDes(el.id)" class="relative">
            <div class="w-[20rem]">
              <img :src="el.img" :alt="el.name" class="rounded-lg bg-gradient-to-b from-white to-black grayscale-[20%]" />
              <div
                class="bg-gradient-to-b from-[#00000000] to-[#000000] absolute w-[20rem] h-[200px] bottom-0 z-20 rounded-lg">
                <div class="flex flex-col gap-1 absolute bottom-4 px-5">
                  <div class="font-bold text-[#FFFFFF]">
                    {{ el.name }}
                  </div>
                  <div class="text-[#FFFFFF] h-[3rem]">
                    {{ el.about }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Tab to show desktop -->
          <div v-show="showLeaderTabDesk">
            <!-- Add the overlay with reduced opacity -->
            <div class="fixed inset-0 bg-gray-300 bg-opacity-20 z-40" @click="showLeaderTabDesk = false"></div>

            <div
              class="z-50 flex justify-center items-center my-8 absolute top-[-3%] xl:top-[-4%] left-0 lg:left-[3%] xl:left-[5%]">
              <div
                class="shadow-lg bg-white md:w-full lg:w-[60rem] xl:w-[70rem] md:h-[33rem] xl:h-[auto] py-8 px-7 lg:px-12"
                v-if="el.id == activeDesId">
                <div class="ml-auto w-max bg-opacity-50" @click="showLeaderTabDesk = false">
                  <img src="@/assets/about/close.svg" alt="close_button" />
                </div>
                <div class="flex justify-evenly items-center">
                  <div class="relative w-[50%]">
                    <img src="@/assets/home/getmentored/bg element.png" alt="bg_element"
                      class="md:w-[290px] lg:w-[300px] xl:w-[350px] xl:h-[454px] mx-auto pr-4" />
                    <img :src="el.img" :alt="el.name"
                      class="absolute top-[5%] left-[1%] z-10 md:w-[18rem] lg:w-[20rem] xl:w-[24rem] rounded-lg" />
                  </div>
                  <div class="w-[50%]">
                    <div>
                      <p class="text-xl font-medium" :class="[isExpanded ? '' : 'line-clamp-3']" v-html="el.description">
                      </p>
                      <button @click="toggleReadMore" class="mt-2 border-b-2 border-b-[#F8C300]">
                        {{ isExpanded ? "Read less" : "Read more" }}
                      </button>
                    </div>
                    <div class="text-4xl font-bold text-[#18479E] py-4">
                      {{ el.name }}
                    </div>
                    <div class="text-2xl font-light text-[#EC027C]">
                      {{ el.about }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Tab to show desktop -->
        </div>
      </div>
    </div>
    <!-- Desktop design -->
  </div>
</template>

<script>
export default {
  name: "leaderViewVue",
  data() {
    return {
      contentData: [
        {
          id: 1,
          name: "Mr. Pradeep Kumar Gupta",
          about: "Hon'ble Chancellor, Sharda University and Chairman, SGI",
          img: require("@/assets/about/Pradeep Kumar Gupta.webp"),
          description:
            "Embracing innovation and inclusivity, we at Sharda University are proud to announce our foray into the online education realm with Sharda Online. Together, let's unlock boundless opportunities for learning and growth, transcending physical barriers and empowering minds worldwide. We want to welcome all the learners to a future where education knows no bounds!",
        },
        {
          id: 2,
          name: "⁠Mr. Yatendra Kumar Gupta",
          about:
            "Hon'ble Pro-Chancellor, Sharda University and Vice Chairman, SGI",
          img: require("@/assets/about/YK SIr.webp"),
          description:
            "Expanding our horizons with Sharda Online, we're thrilled to usher in a new era of education. With flexibility and accessibility at its core, our platform brings learning to your doorstep, breaking down barriers to knowledge. Rest assured, our curriculum aligns with industry standards, ensuring you're prepared for success in the ever-evolving professional landscape.",
        },
        {
          id: 3,
          name: "Prof. (Dr.) Sibaram Khara",
          about: "Hon'ble Vice-Chancellor",
          img: require("@/assets/about/Sibaram Khara.webp"),
          description:
            "Sharda Online is our initiative propelling us into the digital age of education, where we pioneer a transformative journey to learners worldwide. Our commitment to excellence is underscored by a cutting-edge technological platform featuring a state-of-the-art Learning Management System, rich e-learning materials, and an extensive e-library. Join us as we harness technology's power to redefine how you learn, grow, and thrive in today's dynamic world.",
        },
        {
          id: 4,
          name: "⁠Prof. (Dr.) Parma Nand",
          about: "Hon'ble Pro Vice-Chancellor",
          img: require("@/assets/about/Dr.-Parma-Nand.webp"),
          description:
            "Sharda Online epitomizes a groundbreaking shift in education, empowering learners and working professionals globally to attain esteemed degrees without the constraints of location. We're bridging gaps, fostering accessibility, and championing a new era of learning, where anyone, anywhere can thrive.",
        },
        {
          id: 5,
          name: "Mr. Prashant Gupta",
          about: "CEO, Sharda Group",
          img: require("@/assets/about/Mr. Prashant Gupta.webp"),
          description:
            "Mr. Prashant Gupta is the Chief Executive Officer (CEO), Sharda Group and President, Sharda University Uzbekistan He is also the Founder & CEO of Sharda Tech that provides a comprehensive portfolio of world-class services and solutions for ERP and Digital Marketing. <br><br>Mr. Prashant Gupta is the alumni of the very prestigious Nottingham Business School. He has a vast experience in multiple fields of market research, planning business strategy, public relations, and team management. He is among the select young business leaders who have received prestigious honor and recognition from various industry bodies.",
        },
        {
          id: 6,
          name: " Mr. Rishabh Gupta",
          about: "Vice President, Sharda Hospital",
          img: require("@/assets/about/Rishabh Gupta.webp"),
          description:
            "Mr. Rishabh Gupta's journey through the world of hospitality is adorned with excellence and international exposure. He is an alumnus of the esteemed Swiss Hotel Management School in Switzerland, where he honed his skills and gained invaluable experience through internships in Europe and Latin America. His quest for knowledge led him to the United States, where he collaborated with a leading American chain in the hospitality industry, further enriching his global perspective.",
        },
        {
          id: 7,
          name: "Mr. Vivek Kumar Gupta",
          about: "Registrar",
          img: require("@/assets/about/Vivek-Kumar-Gupta.webp"),
          description:
            "At Sharda Online, we're revolutionizing education by offering a wide range of programs to suit diverse needs. But our commitment doesn't end there. We provide comprehensive career support, including mentorship, profile building, and guidance for further education. Be a part of our world and experience a new era of education, where possibilities are endless and knowledge knows no boundaries.",
        },
        {
          id: 8,
          name: "Prof. (Dr.) Raju Sunder",
          about: "Director and Professor",
          img: require("@/assets/about/raju-ganesh-sundar.webp"),
          description:
            "Welcome to the Sharda University Centre for Distance and Online Education (CDOE), an exceptional hub for learning and personal growth. We are a digital learning center dedicated to helping individuals and professionals to achieve their educational aspirations. Our world-class faculty members, boasting extensive research and academic credentials, deliver industry-relevant content in an online learning environment. Here, you will gain access to content that is highly relevant to current and future industry demands. This content is curated from top-tier academic institutions, aligns with best practices endorsed by accrediting bodies, and features insights from leading industry experts in various domains. Your learning journey with us encompasses a rich array of experiences, including teaching, mentorship, hands-on projects, assignments, assessments, virtual labs, tools, simulations, community engagement, and business immersions. I extend my best wishes to you as you embark on your future endeavors. Keep envisioning, living, and achieving your aspirations.",
        },
      ],
      showLeaderTab: false,
      activeId: null,
      showLeaderTabDesk: false,
      activeDesId: null,
      isExpanded: false,
    };
  },
  methods: {
    toggleReadMore() {
      this.isExpanded = !this.isExpanded;
    },

    selectedTab(data) {
      this.activeId = data;
      this.showLeaderTab = true;
    },
    selectedTabDes(data) {
      this.activeDesId = data;
      this.showLeaderTabDesk = true;
    },
  },
};
</script>

<style scoped>
</style>
