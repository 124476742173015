<template>
  <div>
    <headerView />
    <div class="w-full max-w-7xl mx-auto pt-10">
      <!-- search bar -->
      <div class="w-full mx-auto max-w-5xl">
        <div class="pt-[90px]">
          <div class="flex justify-center items-center p-4">
            <div
              class="flex items-center rounded-full border-[3px] border-slate-200"
            >
              <img
                src="@/assets/home/header/search.png"
                alt="searchBar"
                class="ml-4 w-[30px] h-[20px] object-contain"
              />
              <input
                v-model="searchFilter"
                @input="search"
                type="text"
                placeholder="Search"
                class="p-3 w-[20rem] lg:w-[30rem] xl:w-[50rem] 2xl:w-[60rem] rounded-full outline-none placeholder-[#001C54]"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- tabs -->
      <div class="w-full max-w-4xl mx-auto">
        <div class="mt-6">
          <div
            class="flex gap-4 px-5 md:px-0 md:flex md:flex-nowrap md:justify-between md:items-center"
          >
            <button
              v-for="(tab, index) in tabs"
              :key="index"
              class="px-0 md:px-16 py-3 text-md md:text-xl font-bold border w-full md:w-auto"
              :class="{ 'bg-[#EC027C] text-white': activeTab === tab }"
              @click="changeTab(tab)"
            >
              {{ tab }}  
              
              ({{ tab == 'Programs'? programsCount :  tab == 'Electives'?  electivesCount:blogsCount }})
            </button>
          </div>
        </div>
        <!-- Display Counts -->
        <div
          class="text-left mt-10 font-bold text-xl md:text-2xl px-5 lg:px-0"
          v-if="searchFilter"
        >
          <p v-if="activeTab === 'Programs'">
            {{ programsCount }} Searches for
            <span class="uppercase">"{{ searchFilter }}"</span>
          </p>
          <p v-if="activeTab === 'Electives'">
            {{ electivesCount }} Searches for
            <span class="uppercase">"{{ searchFilter }}"</span>
          </p>
          <p v-if="activeTab === 'Blogs'">
            {{ blogsCount }} Searches for
            <span class="uppercase">"{{ searchFilter }}"</span>
          </p>
        </div>

        <div v-if="activeTab === 'Programs'">
          <div class="w-full max-w-4xl mx-auto" v-if="programData.length > 0">
            <div
              v-for="(program, index) in programData"
              :key="index"
              class="pt-7"
              v-show="index < maxItems"
            >
              <a :href="`/${program.slug}`">
                <div
                  class="flex justify-start items-center gap-5 md:gap-16 px-2"
                >
                  <div class="w-[25%]">
                    <img :src="program.thumbnail" alt="" class="" />
                  </div>
                  <div class="w-[75%]">
                    <div class="flex flex-col justify-start text-start gap-1">
                      <h2 class="text-md md:text-2xl font-bold text-[#18479e]">
                        {{ program.name }}
                      </h2>
                      <p
                        class="text-sm md:text-lg line-clamp-2 md:line-clamp-3"
                        v-html="program.description"
                      ></p>
                      <div class="flex gap-5">
                        <img
                          src="@/assets/home/header/calender.svg"
                          alt=""
                          class="w-[15px]"
                        />
                        <p class="text-sm md:text-lg">
                          {{ program.durations }} Years
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <hr
                class="mt-10 border-t border-gray-300"
                v-if="index < programData.length - 1"
              />
            </div>
          </div>
        </div>

        <div v-else-if="activeTab === 'Electives'">
          <div class="w-full max-w-6xl mx-auto" v-if="electiveData.length > 0">
            <div
              v-for="(Elective, index) in electiveData"
              :key="index"
              class="pt-7"
              v-show="index < maxItems"
            >
              <a :href="`/${Elective.short_slug}/${Elective.slug}`">
                <div
                  class="flex justify-start items-center gap-5 md:gap-16 px-2 max-w-4xl"
                >
                  <div class="w-[25%]">
                    <img :src="Elective.image" alt="" />
                  </div>
                  <div class="w-[75%]">
                    <div class="flex flex-col justify-start text-start gap-1">
                      <h2 class="text-md md:text-2xl font-bold text-[#18479e]">
                        {{Elective.sub_name}} {{ Elective.name }}
                      </h2>
                      <p
                        class="text-sm md:text-lg line-clamp-2 md:line-clamp-3"
                        v-html="Elective.description"
                      ></p>
                      <div class="flex justify-start items-center gap-10 pt-2">
                        <div class="flex gap-5">
                          <img
                            src="@/assets/home/header/calender.svg"
                            alt=""
                            class="w-[15px]"
                          />
                          <p class="text-sm md:text-lg">
                            {{ Elective.durations }} Years
                          </p>
                        </div>
                        <div class="flex items-center gap-5">
                          <img src="@/assets/home/header/money.svg" alt="" />
                          <p class="text-sm md:text-lg">
                            {{ formatCurrency(Elective.indian_annual_fee) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <hr
                class="mt-10 border-t border-gray-300"
                v-if="index < electiveData.length - 1"
              />
            </div>
          </div>
        </div>
        <div v-else-if="activeTab === 'Blogs'">
          <div class="w-full max-w-6xl mx-auto" v-if="blogData.length > 0">
            <div
              v-for="(Blog, index) in blogData"
              :key="index"
              class="pt-7"
              v-show="index < maxItems"
            >
              <a :href="`/blogs/${Blog.slug}`">
                <div
                  class="flex justify-start items-center gap-5 md:gap-16 px-2 max-w-4xl"
                >
                  <div class="w-[25%]">
                    <img :src="Blog.image" alt="" class="w-full h-[100px]" />
                  </div>
                  <div class="w-[75%]">
                    <div class="flex flex-col justify-start text-start gap-1">
                      <h2 class="text-md md:text-2xl font-bold text-[#18479e]">
                        {{ Blog.title }}
                      </h2>
                      <p
                        class="text-sm md:text-lg line-clamp-2 md:line-clamp-3"
                      >
                        {{ Blog.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
              <hr
                class="mt-10 border-t border-gray-300"
                v-if="index < blogData.length - 1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Explore More button -->
    <div
      class="w-full flex justify-center py-10"
      v-if="shouldShowExploreButton"
    >
      <p
        class="bg-[#EC027C] text-xl font-bold w-[220px] text-center py-2 rounded-[50px] text-white cursor-pointer"
        @click="toggleExplore"
      >
        {{ exploreButtonText }}
      </p>
    </div>
    <div class="mt-8">
      <footerView />
    </div>
  </div>
</template>

<script>
import headerView from "@/components/common/header.vue";
import footerView from "@/components/common/footer.vue";
import axios from "axios";
export default {
  name: "SearchView",
  components: {
    headerView,
    footerView,
  },
  data() {
    return {
      searchFilter: "",
      tabs: ["Programs", "Electives", "Blogs"],
      activeTab: "Programs",
      programData: [],
      electiveData: [],
      blogData: [],
      maxItems: 4,
      exploreButtonText: "Explore More",
      programsCount: 0,
      electivesCount: 0,
      blogsCount: 0,
    };
  },
  created() {
    this.searchFilter = localStorage.getItem("searchitem") || "";
    const activeTab = localStorage.getItem("activeTab");
    if (activeTab && this.tabs.includes(activeTab)) {
      this.activeTab = activeTab;
    }
    this.search();
  },

  computed: {
    shouldShowExploreButton() {
      const results =
        this.activeTab === "Programs"
          ? this.programData
          : this.activeTab === "Electives"
          ? this.electiveData
          : this.blogData;
      return results.length > this.maxItems;
    },
  },
  methods: {
    async search() {
      try {
        const response = await axios
        .get(
          `${process.env.VUE_APP_API}/lp/landingpage-search/?search=${this.searchFilter}`
        );
        
          console.log("api data", response.data);
          this.programData = response.data.programs || [];
          this.electiveData = response.data.electives || [];
          this.blogData = response.data.blogs || [];
          // Update counts
          this.programsCount = this.programData.length;
          this.electivesCount = this.electiveData.length;
          this.blogsCount = this.blogData.length;
          this.electiveData.forEach((elective) => {
          const associatedProgram = elective.program;
          if (
            associatedProgram &&
            !this.programData.some(
              (prog) => prog.id === associatedProgram.id
            )
          ) {
            this.programData.push(associatedProgram);
            this.programsCount += 1;
          }
        });
      }
        catch (error) {
          console.error("Error fetching search results:", error);
        }
    },

    changeTab(tab) {
      this.activeTab = tab;
    },
    toggleExplore() {
      if (this.exploreButtonText === "Explore More") {
        this.maxItems = Number.MAX_SAFE_INTEGER;
        this.exploreButtonText = "Explore Less";
      } else {
        this.maxItems = 4;
        this.exploreButtonText = "Explore More";
      }
    },
    getTabCount(tab) {
      if (tab === "Programs") return this.programsCount;
      if (tab === "Electives") return this.electivesCount;
      if (tab === "Blogs") return this.blogsCount;
      return 0;
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(value);
    },
  },
};
</script>

<style></style>
