<template>
    <div class="w-full py-3 md:py-6">
      <div class="max-w-7xl mx-auto w-full p-2 relative">
        <h2
          class="text-[24px] lg:text-[30px] text-[#17479e] font-bold text-center capitalize"
        >
          COMPANIES HIRING
        </h2>
  
        <div
          class="absolute bottom-0 right-0 top-[80px] md:top-[52%] md:right-0 z-10 cursor-pointer"
        >
          <img
            src="@/assets/lpProgramm/icon-slider-next.svg"
            class="next-hiring"
            width="40px"
            height="40px"
            alt="slider-next"
          />
        </div>
        <div class="max-w-6xl mx-auto">
          <div class="flex justify-between mt-5 slick-hiring px-8 lg:px-0">
            <div
              v-for="company in companies"
              :key="company.id"
              class="flex justify-center items-center"
            >
              <div
                class="md:flex flex-col gap-4 justify-center items-center bg-white border  shadow rounded-md hidden"
              >
                <img
                  :src="company.imgDes"
                  alt="company logo"
                  class="w-full lg:w-[190px] md:h-[100px] object-contain rounded-xl"
                />
              </div>
  
              <div
                class="flex flex-col gap-4 justify-center items-center bg-white border  shadow rounded-md md:hidden"
              >
                <img
                  :src="company.imgMob"
                  alt="company logo"
                  class="w-[150px] h-[70px] object-contain rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="absolute bottom-0 left-0 top-[80px] md:top-[52%] z-10 cursor-pointer"
        >
          <img
            src="@/assets/lpProgramm/icon-slider-prev.svg"
            width="40px"
            height="40px"
            class="prev-hiring"
            alt="slider prev"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import $ from "jquery";
  export default {
    name: "HiringView",
    data() {
      return {
        companies: [
          {
            id: 1,
            imgDes: require("@/assets/lpProgramm/Adani-wilmar-desktop.webp"),
            imgMob: require("@/assets/lpProgramm/Adani-wilmar-mobile.webp"),
          },
          {
            id: 2,
            imgDes: require("@/assets/lpProgramm/Amar-wilmar-desktop.webp"),
            imgMob: require("@/assets/lpProgramm/Amar-Ujala-mobile.webp"),
          },
          {
            id: 3,
            imgDes: require("@/assets/lpProgramm/amazon-desktop.webp"),
            imgMob: require("@/assets/lpProgramm/Amazon-mobile.webp"),
          },
          {
            id: 4,
            imgDes: require("@/assets/lpProgramm/cmc-wilmar-desktop.webp"),
            imgMob: require("@/assets/lpProgramm/CMC-Limited-mobile.webp"),
          },
          {
            id: 5,
            imgDes: require("@/assets/lpProgramm/distv-destop.webp"),
            imgMob: require("@/assets/lpProgramm/DishTV-mobile.webp"),
          },
          {
            id: 6,
            imgDes: require("@/assets/lpProgramm/future-desktop.webp"),
            imgMob: require("@/assets/lpProgramm/Future-Group-mobile.webp"),
          },
          {
            id: 7,
            imgDes: require("@/assets/lpProgramm/genpact-desktop.webp"),
            imgMob: require("@/assets/lpProgramm/Genpact-mobile.webp"),
          },
          {
            id: 8,
            imgDes: require("@/assets/lpProgramm/HCL-desktop.webp"),
            imgMob: require("@/assets/lpProgramm/HCL-mobile.webp"),
          },
          {
            id: 9,
            imgDes: require("@/assets/lpProgramm/ICICI-desktop.webp"),
            imgMob: require("@/assets/lpProgramm/ICICI-mobile.webp"),
          },
          {
            id: 10,
            imgDes: require("@/assets/lpProgramm/NIIT-desktop.webp"),
            imgMob: require("@/assets/lpProgramm/NIIT-mobile.webp"),
          },
          {
            id: 11,
            imgDes: require("@/assets/home/hiring/pepsi-desktop.webp"),
            imgMob: require("@/assets/home/hiring/Pepsi-mobile.webp"),
          },
          {
            id: 12,
            imgDes: require("@/assets/home/hiring/sleepwell-desktop.webp"),
            imgMob: require("@/assets/home/hiring/Sleepwell-mobile.webp"),
          },
          {
            id: 13,
            imgDes: require("@/assets/home/hiring/syntel-desktop.webp"),
            imgMob: require("@/assets/home/hiring/Syntel-mobile.webp"),
          },
          {
            id: 14,
            imgDes: require("@/assets/home/hiring/tech-mahindra-desktop.webp"),
            imgMob: require("@/assets/home/hiring/Tech-Mahindra-mobile.webp"),
          },
          {
            id: 15,
            imgDes: require("@/assets/home/hiring/voda-desktop.webp"),
            imgMob: require("@/assets/home/hiring/Vodafone-mobile.webp"),
          },
          {
            id: 16,
            imgDes: require("@/assets/home/hiring/zeetv-desktop.webp"),
            imgMob: require("@/assets/home/hiring/Zee-TV-mobile.webp"),
          },
        ],
      };
    },
    mounted() {
      $(".slick-hiring").slick({
        infinite: true,
        autoplay: true,
        speed: 200,
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow: $(".prev-hiring"),
        nextArrow: $(".next-hiring"),
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
  };
  </script>
  
  <style>
  .slick-slider .slick-track {
    display: flex;
    gap: 12px;
  }
  </style>
  