<template>
  <div>
    <headerView />
    <!--banner-->
    <div class="bg-gradient-to-b from-white to-[#EFEFEF] py-4">
      <div class="max-w-7xl mx-auto mt-[3rem] lg:mt-[6.5rem]">
        <div class="px-5 hidden lg:block">
          <p class="text-[#0CB1EF] md:text-black">
            <a href="/" class="text-[#EC027C]">Home</a>
            <span class="text-[#EC027C]"> &nbsp;/&nbsp;</span>
            <a class="text-[#4D4D4D]">Blog</a>
          </p>
        </div>
        <div class="max-w-6xl mx-auto">
          <div
            class="mt-[5rem] lg:mt-[0rem] mb-8 w-max m-auto lg:flex lg:py-8 lg:flex-row-reverse lg:gap-x-[3rem] xl:gap-[5rem]"
          >
            <div class="w-[20rem]">
              <img src="@/assets/blogs/blogBanner.png" alt="blogBanner" />
            </div>
            <div class="py-4 lg:mt-[3rem] xl:mt-[3rem]">
              <div
                class="text-[#18479E] text-[30px] lg:text-[45px] xl:text-[60px] font-medium tracking-normal lg:leading-[4rem]"
              >
                Expert Insights from the <br />
                <span class="text-[#EC027C]">Academic World</span>
              </div>
              <div class="w-[18rem] lg:text-[24px] lg:w-[35rem] xl:w-[50rem]">
                Fuel your mind with fresh perspectives and in-depth analysis, as
                you discover the latest trends, tips, and thought leadership
                that shape the future.
              </div>
            </div>
            <!--  -->
          </div>
        </div>
      </div>
    </div>
    <!--banner-->
    <!--latest Articles-->
    <div class="m-auto max-w-7xl my-8">
      <div
        class="text-[#18479E] w-max m-auto text-3xl lg:text-[60px] pb-8 md:pt-4"
      >
        Latest <span class="text-[#EC027C]">Articles</span>
      </div>
      <div
        class="latestArticleCarousel py-6 md:w-[40rem] lg:w-[55rem] xl:w-[75rem] m-auto"
      >
        <div v-for="el in sortedData" :key="el.id" class="">
          <div
            class="flex justify-center flex-col items-center md:flex-row-reverse md:justify-around"
          >
            <div class="ssm:w-[18rem] w-[20rem] lg:hidden">
              <img :src="el.mobile_banner" alt="title" />
            </div>
            <div class="w-[30rem] hidden lg:block">
              <img :src="el.image" alt="title" />
            </div>
            <div class="w-max m-auto">
              <div
                class="w-[18rem] xl:w-[24rem] text-[20px] lg:text-[40px] py-2 font-bold"
              >
                <a :href="`/blogs/${el.slug}`"> {{ el.title }} </a>
              </div>
              <div class="flex gap-x-2">
                <div class="text-[12px] lg:text-[14px]">
                  {{ el.category.title }} |
                </div>
                <div class="text-[12px] lg:text-[14px]">
                  {{ formatDate(el.publish_at) }}
                </div>
              </div>
              <a :href="`/blogs/${el.slug}`">
                <div
                  class="bg-[#EC027C] w-max py-3 px-8 rounded-full my-3 text-white text-[16px] font-bold"
                >
                  Read article
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--latest Articles-->
    <!--featured Articles-->
    <div class="max-w-7xl m-auto">
      <div
        class="text-3xl lg:text-[60px] text-[#18479E] px-12 w-max md:m-auto pb-12"
      >
        Featured <span class="text-[#EC027C]"> Articles</span>
      </div>
      <div
        class="my-3 flex flex-col items-center justify-center xl:justify-start xl:ml-[4rem] md:flex-row md:flex-wrap md:gap-x-6"
      >
        <div v-for="el in allBlogsData" :key="el.id" class="mb-6">
          <div class="flex flex-col justify-center items-center w-max">
            <div class="ssm:w-[18rem] w-[20rem] lg:w-[22rem]">
              <img :src="el.image" :alt="el.title" />
            </div>
            <div
              class="w-[18rem] lg:w-[20rem] text-[18px] my-3 self-start ml-4 line-clamp-2"
            >
              <a :href="`/blogs/${el.slug}`" class="self-start">
                {{ el.title }}
              </a>
            </div>
            <div class="flex gap-x-3 self-start ml-4">
              <div class="text-[14px]">{{ el.category.title }} |</div>
              <div class="text-[14px]">{{ formatDate(el.publish_at) }}</div>
            </div>

            <!-- <div
                class="text-[16px] my-2 ml-4 text-[#EC027C] cursor-pointer"
              >
                Read More
              </div> -->
          </div>
        </div>
      </div>
    </div>
    <!--featured Articles-->
    <!--all article-->
    <div class="max-w-7xl m-auto">
      <div
        class="flex flex-col md:flex-row md:justify-around xl:justify-between items-center gap-y-4 md:gap-y-0 ssm:ml-4 px-4 md:my-8"
      >
        <div class="text-3xl lg:text-[60px] text-[#18479E]">
          All <span class="text-[#EC027C]">Articles</span>
        </div>

        <select
          name=""
          id=""
          v-model="category"
          class="rounded-full w-[18rem] xl:w-[24rem] p-2 bg-white border-[#EC027C] border outline-none lg:py-4"
          @change="handleChange"
        >
          <option value="" class="px-4">Select a Category</option>
          <option v-for="el in categroyList" :key="el.id" :value="el.title">
            {{ el.title }}
          </option>
        </select>
      </div>
      <div
        class="py-4 lg:pb-8 lg:px-8 flex flex-col items-center gap-y-8 md:flex-row md:flex-wrap md:justify-center md:gap-x-6 md:gap-y-6"
        v-if="displayData.length > 0"
      >
        <div v-for="el in displayData" :key="el.id" class="">
          <div
            class="rounded-xl ssm:w-[18rem] w-[20rem] h-[9rem] m-auto shadow-lg"
          >
            <div class="text-[18px] px-4 mb-2 pt-4">
              <a :href="`/blogs/${el.slug}`">
                {{ el.title }}
              </a>
            </div>
            <div class="flex px-4 mb-4">
              <div class="text-[14px] text-[#4D4D4D]">
                {{ el.category.title }} |
              </div>
              <div class="text-[14px] text-[#4D4D4D] pl-1">
                {{ formatDate(el.publish_at) }}
              </div>
            </div>

            <!-- <div class="text-[#EC027C] text-[16px] px-4 pb-10">Read More</div> -->
          </div>
        </div>
      </div>
      <div v-else class="text-2xl my-8 ml-8">Data not found</div>

      <div
        v-if="canLoadMore"
        class="text-[16px] text-center my-2 mb-8 ml-4 text-[#EC027C] cursor-pointer"
        @click="loadMore"
      >
        Load More
      </div>
    </div>
    <!--all article-->
    <stickyView />
    <footerView />
  </div>
</template>

<script>
import headerView from "@/components/common/header.vue";
import footerView from "@/components/common/footer.vue";
import stickyView from "@/components/common/sticky.vue";
import $ from "jquery";
import "slick-carousel";
import axios from "axios";
export default {
  name: "blogView",
  components: {
    headerView,
    footerView,
    stickyView,
  },
  data() {
    return {
      sortedData: [],
      categroyList: [],
      category: "",
      allBlogsData: [],
      filterData: [], // All data loaded from the backend
      displayData: [], // Data currently displayed
      itemsPerPage: 6,
      currentPage: 1,
      canLoadMore: true,
    };
  },
  async created() {
    await this.getCategroyListData();
    await this.allBlogs();
    this.latestArticle();
    this.getCategoryFilter();
    this.updateDisplayData();
  },
  methods: {
    formatDate(dateString) {
      const options = { month: "short", year: "numeric" };
      const formattedDate = new Date(dateString).toLocaleDateString(
        "en-IN",
        options
      );
      // Extract day with appropriate suffix (e.g., 1st, 2nd, 3rd, 4th)
      const dayWithSuffix = this.getDayWithSuffix(
        new Date(dateString).getDate()
      );
      // Combine the formatted date and day with suffix
      return `${dayWithSuffix} ${formattedDate}`;
    },
    getDayWithSuffix(day) {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    },
    async allBlogs() {
      await axios
        .get(`${process.env.VUE_APP_API}/blog/blog-list`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.allBlogsData = resp.data.data;
            this.sortedData = this.allBlogsData.slice(0, 3);
            this.getCategoryFilter();
          } else {
            this.$router.push({
              name: "PageNotFound",
              params: { pathMatch: "page-not-found" },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleChange() {
      this.getCategoryFilter();
    },
    async getCategroyListData() {
      await axios
        .get(`${process.env.VUE_APP_API}/blog/category-list/`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.categroyList = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getCategoryFilter() {
      try {
        if (this.category) {
          const response = await axios.get(
            `${process.env.VUE_APP_API}/blog/blog-list/`,
            {
              params: { category__title: this.category },
            }
          );
          if (response.data.status == 200) {
            this.filterData = response.data.data;
          } else {
            this.filterData = [];
          }
        } else {
          this.filterData = this.allBlogsData;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    updateDisplayData() {
      const end = this.currentPage * this.itemsPerPage;
      this.displayData = this.filterData.slice(0, end);
      this.canLoadMore = this.filterData.length > end;
    },
    loadMore() {
      this.currentPage += 1;
      this.updateDisplayData();
    },
    latestArticle() {
      $(`.latestArticleCarousel`).slick({
        infinite: true,
        autoplay: true,
        prevArrow: null,
        nextArrow: null,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        dotsClass: "slick-dots-blog",
      });
    },
  },
  mounted() {},
};
</script>

<style>
.slick-dots-blog {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2rem;
  padding: 0;
  list-style-type: none;
  position: absolute;
  left: 10%;
}
@media (min-width: 768px) {
  .slick-dots-blog {
    left: -1%; /* Adjust horizontal positioning */
  }
}

@media (min-width: 1440px) {
  .slick-dots-blog {
    left: 14%; /* Adjust horizontal positioning */
  }
}

.slick-dots-blog li {
  margin: 0 0.25rem;
}

.slick-dots-blog button {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  padding: 0;
  margin: 5px;
  border: none;
  border-radius: 100%;
  background-color: #18479e;
  text-indent: -9999px;
}

.slick-dots-blog li.slick-active button {
  background-color: #ec027c;
  width: 0.5rem;
  height: 0.5rem;
}
</style>
