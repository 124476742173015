<template>
  <div class="max-w-7xl m-auto">
    <div class="py-8">
      <div
        class="m-auto w-[15rem] lg:w-[45rem] text-center text-[#EC027C] text-3xl lg:text-5xl lg:text-[#18479E] lg:font-medium"
      >
        Opportunities Await You with an
      </div>
      <div
        class="m-auto w-max text-[#18479E] lg:text-[#EC027C] text-3xl lg:text-5xl mt-2 lg:mt-4 lg:font-medium"
      >
        Online {{ program.name }} Degree
      </div>
    </div>
    <!--cards-->
    <div>
      <div
        class="scroll-container flex justify-start xl:justify-center items-center overflow-x-auto lg:overscroll-none gap-x-4 p-[2rem]"
      >
        <div v-for="(el, index) in program.opportunities" :key="index">
          <div
            class="bg-[#18479E] rounded-lg px-[2.2rem] lg:px-0 w-[15rem] lg:w-[13rem] h-[16rem]"
          >
            <div class="text-[#0CB1EF] w-max m-auto py-6 font-bold text-4xl">
              {{ el.job_opening }}+
            </div>
            <div
              class="w-[12rem] m-auto text-white text-lg text-center  font-medium"
            >
              Jobs in <br />
              {{ el.title }}
            </div>
            <!-- <div class="w-max m-auto pt-3 text-white font-normal text-[14px]">
              Avg experience
            </div>
            <div class="w-max m-auto text-white font-normal text-[14px]">
              1-4 years
            </div> -->
            <!-- <div class="w-max m-auto text-white font-normal text-[14px]">
              Avg Starting Salary
            </div> -->
            <div
              class="text-center m-auto text-white font-normal text-[14px] pb-8 pt-4 px-2"
            >
              {{ newStr(el.content) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--cards-->
  </div>
</template>

<script>
export default {
  name: "opportunityVue",
  props: ["program"],
  data() {
    return {};
  },
  methods: {
    newStr(data) {
      return data.replace(/\|/g, "");
    },
  },
};
</script>

<style scoped>
.scroll-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari and Opera */
}
</style>
