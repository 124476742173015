<template>
  <div class="">
    <headerView />
    <div class="bg-gradient-to-b from-[#FFFFFF] to-[#EFEFEF] px-5 lg:px-8">
      <div class="max-w-7xl mx-auto">
        <div class="flex flex-col justify-center h-[250px] lg:h-[350px]">
          <h1
            class="text-[30px] lg:text-[60px] text-[#18479E] font-medium lg:font-bold text-left lg:pt-20" 
          >
            Refund policy
          </h1>
          <!-- <p class="text-[12px] lg:text-[18px] text-[#000000]">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. <br />
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s,
          </p> -->
        </div>
      </div>
    </div>
    <div class="px-5 lg:px-8">
      <div class="max-w-7xl mx-auto py-10">
        <div class="flex flex-col gap-4">
          <h2 class="text-[16px] lg:text-[30px] text-[#000000] font-semibold">
            Admission Cancellation and Refund Rules
          </h2>
          <p class="text-[12px] lg:text-[20px] text-[#000000] font-medium">
            The fee refund policy and guidelines of the University applicable to
            online programs is hereby notified.
          </p>

          <div>
            <h2
              class="text-[16px] lg:text-[24px] text-[#18479E] font-semibold py-3 lg:py-8 border-b-2 border-gray-200"
            >
              Components of fees:
            </h2>
            <div
              class="flex py-3 lg:py-8 items-center justify-between border-b-2 border-gray-200"
            >
              <p class="text-[14px] lg:text-[16px] w-full">
                Student application is <br />
                cancelled by the University
              </p>
              <div class="w-full">
                <img
                  src="@/assets/refund/Polygon 5.svg"
                  alt=""
                  srcset=""
                  class="w-[20px] mx-auto h-[18px]"
                />
              </div>
              <p class="text-[14px] lg:text-[16px] w-full">
                Full refund of Program Fee and <br />
                Examination Fee & Registration Fee
              </p>
            </div>
            <div
              class="flex py-3 lg:py-8 items-center justify-between border-b-2 border-gray-200"
            >
              <p class="text-[14px] lg:text-[16px] w-full">
                Program has been <br />
                cancelled by the University
              </p>
              <div class="w-full">
                <img
                  src="@/assets/refund/Polygon 5.svg"
                  alt=""
                  srcset=""
                  class="w-[20px] mx-auto h-[18px]"
                />
              </div>
              <p class="text-[14px] lg:text-[16px] w-full">
                Full refund of Program Fee, <br />
                Examination Fee and Registration Fee
              </p>
            </div>
          </div>

          <div>
            <h2
              class="text-[16px] lg:text-[24px] text-[#18479E] font-semibold py-3 lg:py-8 border-b-2 border-gray-200"
            >
              If cancellation of admission and refund of fee request is made by
              the student:
            </h2>
            <div
              class="flex py-3 lg:py-8 items-center justify-between border-b-2 border-gray-200"
            >
            <h2
              class="text-[14px] lg:text-[18px] w-full text-[#18479E] font-bold py-3"
            >
            Time of Request
            </h2>
            <h2
              class="text-[14px] text-center w-full lg:text-[18px] text-[#18479E] font-bold py-3"
            >
            Amount of Refund
            </h2>
            </div>
            <div
              class="flex py-3 lg:py-8 items-center justify-between border-b-2 border-gray-200"
            >
            
              <p class="text-[14px] lg:text-[16px] w-full">
                15 days or more prior to the commencement <br />
                of classes (as notified in Academic Calendar)
              </p>
              <div class="w-full">
                <img
                  src="@/assets/refund/Polygon 5.svg"
                  alt=""
                  srcset=""
                  class="w-[20px] mx-auto h-[18px]"
                />
              </div>
              <p class="text-[14px] lg:text-[16px] w-full">
                100% of fee paid <br />(Processing Fee of Rs. 1000 will be
                deducted as processing fee)
              </p>
            </div>
            <div
              class="flex py-3 lg:py-8 items-center justify-between border-b-2 border-gray-200"
            >
              <p class="text-[14px] lg:text-[16px] w-full">
                Less than 15 days before the commencement <br />of classes (as
                notified in Academic Calendar)
              </p>
              <div class="w-full">
                <img
                  src="@/assets/refund/Polygon 5.svg"
                  alt=""
                  srcset=""
                  class="w-[20px] h-[18px] mx-auto"
                />
              </div>
              <p class="text-[14px] lg:text-[16px] w-full">90% of fee paid</p>
            </div>
            <div
              class="flex py-3 lg:py-8 items-center justify-between border-b-2 border-gray-200"
            >
              <p class="text-[14px] lg:text-[16px] w-full">
                15 days or less after the commencement <br />
                of classes (as notified in Academic Calendar)
              </p>
              <div class="w-full">
                <img
                  src="@/assets/refund/Polygon 5.svg"
                  alt=""
                  srcset=""
                  class="w-[20px] h-[18px] mx-auto"
                />
              </div>
              <p class="text-[14px] lg:text-[16px] w-full">80% of fee paid</p>
            </div>
            <div
              class="flex py-3 lg:py-8 items-center justify-between border-b-2 border-gray-200"
            >
              <p class="text-[14px] lg:text-[16px] w-full">
                More than 15 days but less than 30 days after the <br />
                commencement of classes (as notified in Academic Calendar)
              </p>
              <div class="w-full">
                <img
                  src="@/assets/refund/Polygon 5.svg"
                  alt=""
                  srcset=""
                  class="w-[20px] mx-auto h-[18px]"
                />
              </div>
              <p class="text-[14px] lg:text-[16px] w-full">50% of fee paid</p>
            </div>
            <div
              class="flex py-3 lg:py-8 items-center justify-between border-b-2 border-gray-200"
            >
              <p class="text-[14px] lg:text-[16px] w-full">
                More than 30 days after the commencement <br />of classes (as
                notified in Academic Calendar)
              </p>
              <div class="w-full">
                <img
                  src="@/assets/refund/Polygon 5.svg"
                  alt=""
                  srcset=""
                  class="w-[20px] mx-auto h-[18px]"
                />
              </div>
              <p class="text-[14px] lg:text-[16px] w-full">NIL</p>
            </div>
          </div>

          <a href="https://shardaonline.online/pdf/refund-form.pdf" target="_blank"><button
            class="text-[14px] lg:text-[18px] underline underline-offset-1 text-left border-[#EC027C] text-[#EC027C] font-semibold"
          >
            Refund Document Link
          </button></a>
        </div>
      </div>
    </div>

    <footerView />
    <stickyView />
  </div>
</template>

<script>
import headerView from "@/components/common/header.vue";
import footerView from "@/components/common/footer.vue";
import stickyView from "@/components/common/sticky.vue";
export default {
  name: "RefundPolicy",
  components: {
    headerView,
    footerView,
    stickyView,
  },
};
</script>

<style></style>
