<template>
    <div class="px-5 lg:px-8">
      <div class="max-w-6xl mx-auto lg:py-20">
        <!-- desktop -->
        <div class="hidden lg:block">
          <div class="flex flex-col lg:flex-row gap-5 items-center">
            <div class="w-[400px]">
              <h2
                class="text-[30px] lg:text-[40px] xl:text-[55px] font-normal leading-tight"
              >
                <span class="text-[#18479E]">Get </span> <br />
                <span class="text-[#EC027C] whitespace-nowrap"
                  >Mentored By <br />
                  India’s
                </span>
                <span class="text-[#18479E]">Best</span>
              </h2>
  
              <div class="pt-10 flex justify-start gap-4">
                <div
                  class="border rounded-3xl w-[91px] h-[49px] p-3 prev-mentor flex items-center justify-center cursor-pointer"
                  @mouseover="changeBgColorLeft"
                  @mouseleave="resetBackgroundColorLeft"
                  :style="{
                    backgroundColor: backgroundColorTWo,
                    borderColor: borderColorTwo,
                  }"
                >
                  <img
                    src="@/assets/home/getmentored/Icon feather-arrow-left@2x.png"
                    alt=""
                    srcset=""
                    class="w-[16px] h-[16px]"
                    v-if="!toggleBgImgOne"
                  />
                  <img
                    src="@/assets/home/getmentored/Icon feather-arrow-left@2xwhite.png"
                    alt=""
                    srcset=""
                    class="w-[16px] h-[16px]"
                    v-else
                  />
                </div>
                <div
                  class="rounded-3xl p-3 w-[91px] h-[49px] border next-mentor flex items-center justify-center cursor-pointer"
                  @mouseover="changeBgColor"
                  @mouseleave="resetBackgroundColor"
                  :style="{
                    backgroundColor: backgroundColor,
                    borderColor: borderColorOne,
                  }"
                >
                  <img
                    src="@/assets/home/getmentored/Icon feather-arrow-right.webp"
                    alt=""
                    srcset=""
                    class="w-[16px] h-[16px]"
                    v-if="!toggleBgImgTwo"
                  />
  
                  <img
                    src="@/assets/home/getmentored/Icon feather-arrow-right@2x.png"
                    alt=""
                    srcset=""
                    class="w-[16px] h-[16px]"
                    v-else
                  />
                </div>
              </div>
            </div>
  
            <div class="w-[70%]">
              <div class="flex justify-between slick-mentor">
                <div
                  v-for="mentor in mentors"
                  :key="mentor"
                  class="xl:h-[500px]"
                >
                  <div class="">
                    <div class="cards relative">
                      <img
                        src="@/assets/home/getmentored/bg element@2x.png"
                        alt="border-background"
                        class="lg:w-[350px] lg:h-[355px] xl:w-[390px] xl:h-[430px] current  flex gap-2"
                      />
                   
                      <img
                        :src="mentor.image"
                        alt=""
                        class="lg:w-[300px] lg:h-[360px] xl:w-[370px] xl:h-[450px] absolute top-5 rounded-2xl file "
                      />
  
                      <!-- <img
                        src="@/assets/home/students/Group-27.svg"
                        alt="play"
                        class="absolute top-[12rem] left-[10rem] z-50 current"
                      /> -->
                      <div
                        class="bg-gradient-to-b from-[#00000000] to-[#000000] absolute lg:w-[300px] xl:w-[370px] bottom-[-39px] h-[300px]  left-0 z-30 current rounded-b-xl"
                      ></div>
                      
                      <div
                        class="absolute bottom-0 left-7 w-[330px] z-30  current"
                      >
                        <h2 class="lg:text-[20px] font-semibold text-white py-2">
                          {{ mentor.name }}
                        </h2>
                        <div class="flex flex-col gap-3">
                          <div class="flex gap-3" v-if="mentor.teaches != ''">
                            <img
                              src="@/assets/home/getmentored/Icon awesome-graduation-cap.svg"
                              class="w-[20px] h-[20px]"
                              alt=""
                              srcset=""
                            />
                            <p class="text-[14px] text-white">
                              Niche area: {{ mentor.teaches }}
                            </p>
                          </div>
                          <div class="flex gap-3">
                            <img
                              src="@/assets/home/getmentored/Working.webp"
                              class="w-[20px] h-[20px]"
                              alt=""
                              srcset=""
                            />
                            <p class="text-[14px] text-white">
                              {{ mentor.desgination }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- mobile -->
        <div class="block lg:hidden">
          <h2 class="text-[30px] text-center font-normal leading-tight py-10">
            <span class="text-[#18479E]">Get </span>
            <span class="text-[#EC027C]"
              >Mentored By <br />
              India’s
            </span>
            <span class="text-[#18479E]">Best</span>
          </h2>
          <div>
            <div class="flex slick-mentor_mobile">
              <div v-for="mentor in mentors" :key="mentor" class="">
                <div class="h-[400px]">
                  <div class="cards relative w-[308px] mx-auto">
                    <div class="flex justify-end">
                      <img
                        src="@/assets/home/getmentored/bg element@2x.png"
                        alt="border-background"
                        class="w-[280px] h-[283px]"
                      />
                    </div>
                    <img
                      :src="mentor.image"
                      alt=""
                      class="w-[290px] h-[340px] left-3 absolute top-5 rounded-lg bg-gradient-to-b from-[#00000000] to-[#000000]"
                    />
                    <!-- <img
                      src="@/assets/home/students/Group-27.svg"
                      alt="play"
                      class="absolute top-[8rem] left-[6rem] z-50 "
                    /> -->
                    <div
                      class="bg-gradient-to-b from-[#00000000] to-[#000000] absolute w-[290px] h-[340px] top-5 left-3 z-30 rounded-lg"
                    ></div>
                    <div class="absolute bottom-[-55px] left-7 z-30">
                      <h2 class="text-[20px] font-semibold text-white py-2">
                        {{ mentor.name }}
                      </h2>
                      <div class="flex flex-col gap-3">
                        <div class="flex gap-3" v-if="mentor.teaches != ''">
                          <img
                            src="@/assets/home/getmentored/Icon awesome-graduation-cap.svg"
                            class="w-[20px] h-[20px]"
                            alt=""
                            srcset=""
                          />
                          <p class="text-[14px] text-white w-[15rem]">
                            Niche area: {{ mentor.teaches }}
                          </p>
                        </div>
                        <div class="flex gap-3">
                          <img
                            src="@/assets/home/getmentored/Icon awesome-graduation-cap.svg"
                            class="w-[20px] h-[20px]"
                            alt=""
                            srcset=""
                          />
                          <p class="text-[14px] text-white">
                            {{ mentor.desgination }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-4 flex justify-center gap-4">
              <div
                class="border rounded-3xl w-[91px] h-[49px] p-3 prev-mentor_mobile flex items-center justify-center"
                @mouseover="changeBgColorLeft"
                @mouseleave="resetBackgroundColorLeft"
                :style="{
                  backgroundColor: backgroundColorTWo,
                  borderColor: borderColorTwo,
                }"
              >
                <img
                  src="@/assets/home/getmentored/Icon feather-arrow-left@2x.png"
                  alt=""
                  srcset=""
                  class="w-[16px] h-[16px]"
                  v-if="!toggleBgImgOne"
                />
                <img
                  src="@/assets/home/getmentored/Icon feather-arrow-left@2xwhite.png"
                  alt=""
                  srcset=""
                  class="w-[16px] h-[16px]"
                  v-else
                />
              </div>
              <div
                class="rounded-3xl p-3 w-[91px] border h-[49px] next-mentor_mobile flex items-center justify-center"
                @mouseover="changeBgColor"
                @mouseleave="resetBackgroundColor"
                :style="{
                  backgroundColor: backgroundColor,
                  borderColor: borderColorOne,
                }"
              >
                <img
                  src="@/assets/home/getmentored/Icon feather-arrow-right.webp"
                  alt=""
                  srcset=""
                  class="w-[16px] h-[16px]"
                  v-if="!toggleBgImgTwo"
                />
  
                <img
                  src="@/assets/home/getmentored/Icon feather-arrow-right@2x.png"
                  alt=""
                  srcset=""
                  class="w-[16px] h-[16px]"
                  v-else
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import $ from "jquery";
  import "slick-carousel";
  export default {
    name: "GetmentorhomeView",
    props: ["facultyToShow"],
    data() {
      return {
        backgroundColor: "",
        borderColorOne: "#4D4D4D",
        borderColorTwo: "#4D4D4D",
        backgroundColorTWo: "",
        toggleBgImgOne: false,
        toggleBgImgTwo: false,
        mentors: [
          {
            id: 1,
            image: require("@/assets/home/getmentored/raju-ganesh-sundaR.webp"),
            name: "Prof.(Dr.) Raju Ganesh Sunder",
            desgination: "Director, CDOE",
            teaches: "Performance & Energy Management",
            porgramm: "online-mba",
          },
          {
            id: 2,
            image: require("@/assets/home/getmentored/Shubh-Arora.webp"),
            name: "Dr. Shubh Arora",
            desgination: "Associate Professor",
            teaches: "Service Marketing",
            porgramm: "online-mba",
          },
          {
            id: 3,
            image: require("@/assets/home/getmentored/Dr Tanya Rastogi.webp"),
            name: "Dr. Tanya Rastogi",
            desgination: "Assistant Professor",
            teaches: "Circular Economy",
            porgramm: "online-mcom",
          },
          {
            id: 4,
            image: require("@/assets/home/getmentored/Dr Sandeep Kumar.webp"),
            name: "Dr. Sandeep Kumar",
            desgination: "Assistant Professor",
            teaches: "Public Relations",
            porgramm: "online-mba",
          },
          {
            id: 5,
            image: require("@/assets/home/getmentored/Kirt-prashar.webp"),
            name: "Ms. Kirti Prashar",
            desgination: "Assistant Professor",
            teaches: "Consumer Behaviour",
            porgramm: "online-bba",
          },
          // {
          //   id: 6,
          //   image: require("@/assets/home/getmentored/Aditya-Tandon.webp"),
          //   name: "Ms. Madhu Verma",
          //   desgination: "Assistant Professor",
          //   teaches: ""
          // },
  
          {
            id: 7,
            image: require("@/assets/home/getmentored/Vandana.webp"),
            name: "Ms. Vandana",
            desgination: "Assistant Professor",
            teaches: "Financial Accounting",
            porgramm: "online-mcom",
          },
          {
            id: 8,
            image: require("@/assets/home/getmentored/Aditya-Tandon.webp"),
            name: "Mr. Aditya Tandon",
            desgination: "Assistant Professor",
            teaches: "Internet of Everything",
            porgramm: "online-bca",
          },
          {
            id: 9,
            image: require("@/assets/home/getmentored/Priyanka-Joshi.webp"),
            name: "Ms. Priyanka Joshi",
            desgination: "Assistant Professor",
            teaches: "Deep learning applications in Healthcare",
            porgramm: "online-bca",
          },
          {
            id: 10,
            image: require("@/assets/home/getmentored/Kamala-Kumari.webp"),
            name: "Dr. Kamala Kumari",
            desgination: "Assistant Professor",
            teaches: "Foreign Policy",
            porgramm: "online-ba",
          },
          {
            id: 11,
            image: require("@/assets/home/getmentored/Shama-Akthar.webp"),
            name: "Dr. Shama Akhtar",
            desgination: "Assistant Professor",
            teaches: "Administration and Public Policy",
            porgramm: "online-ba",
          },
          {
            id: 12,
            image: require("@/assets/home/getmentored/Dr Premendra Kumar Singh copy.webp"),
            name: "Dr. Premendra Kumar  Singh",
            desgination: "Assistant Professor",
            teaches: "Corporate Social Responsibility",
            porgramm: "online-bba",
          },
          {
            id: 13,
            image: require("@/assets/home/getmentored/Ms Shreyi Mittal copy.webp"),
            name: "Ms. Shreyi Mittal",
            desgination: "Assistant Professor",
            teaches: "Information Retrieval",
            porgramm: "online-bca",
          },
          {
            id: 14,
            image: require("@/assets/home/getmentored/Dr Aashima Bangia pic.webp"),
            name: "Dr Aashima Bangia",
            desgination: "Assistant Professor",
            teaches: "Dynamical Multifaceted Systems in Data Science",
            porgramm: "online-mca",
          },
          {
            id: 15,
            image: require("@/assets/home/getmentored/Ms Eesha Gupta.webp"),
            name: "Ms. Eesha Gupta",
            desgination: "Assistant Professor",
            teaches: "Python programming for Data Science",
            porgramm: "online-mca",
          },
          // {
          //   id: 16,
          //   image: require("@/assets/home/getmentored/Ankit-Tayal-mobile.webp"),
          //   name: "Mr. Ankit Tayal",
          //   desgination: "Assistant Registrar",
          //   teaches: "",
          //   porgramm: "",
          // },
          // {
          //   id: 17,
          //   image: require("@/assets/home/getmentored/Sachin.5d82f844.webp"),
          //   name: "Mr. Sachin Sharma",
          //   desgination: "Manager, Instructional Design",
          //   teaches: "",
          //   porgramm: "",
          // },
          // {
          //   id: 18,
          //   image: require("@/assets/home/getmentored/Mr-Mohit-Tyagi.webp"),
          //   name: "Mr. Mohit Tyagi",
          //   desgination: "Manager, Video Production",
          //   teaches: "",
          //   porgramm: "",
          // },
          // {
          //   id: 19,
          //   image: require("@/assets/home/getmentored/Gagandeep.webp"),
          //   name: "Mr. Gagandeep",
          //   desgination: "Senior Video Editor",
          //   teaches: "",
          //   porgramm: "",
          // },
          {
            id: 20,
            image: require("@/assets/home/getmentored/Madhu-Verma.webp"),
            name: "Ms. Madhu Verma",
            desgination: "Assistant Professor",
            teaches: "Business Economics",
            porgramm: "online-mcom",
          },
          {
            id: 21,
            image: require("@/assets/home/getmentored/Avinash Bhowate.webp"),
            name: "Dr. Avinash Govindrao Bhowate",
            desgination: "Assistant Professor",
            teaches: "Medical Tourism",
            porgramm: "online-mba",
          },
          {
            id: 22,
            image: require("@/assets/home/getmentored/Dr Falguni Singh copy.webp"),
            name: "Dr. Falguni Singh",
            desgination: "Assistant Professor",
            teaches: "Corporate Finance",
            porgramm: "online-bba",
          },
        ],
        newMentorData: [],
      };
    },
    updated() {
        if (this.facultyToShow != "") {
        this.udatedMentors();
      } else {
        this.newMentorData = this.mentors;
      }
    },
    mounted() {
    
      $(".slick-mentor").slick({
        infinite: true,
        autoplay: true,
        speed: 200,
        slidesToShow: 2,
        slidesToScroll: 1,
        leftMode: true,
        centerPadding: "0px",
        prevArrow: $(".prev-mentor"),
        nextArrow: $(".next-mentor"),
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
      $(".slick-mentor_mobile").slick({
        infinite: true,
        autoplay: true,
        speed: 200,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: $(".prev-mentor_mobile"),
        nextArrow: $(".next-mentor_mobile"),
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },
    methods: {
      udatedMentors() {
        this.newMentorData = this.mentors.filter(
          (item) => item.porgramm == this.facultyToShow
        );
      },
      changeBgColor() {
        this.backgroundColor = "#EC027C";
        this.toggleBgImgTwo = true;
        this.borderColorOne = "";
      },
      resetBackgroundColor() {
        this.backgroundColor = "";
        this.toggleBgImgTwo = false;
        this.borderColorOne = "#4D4D4D";
      },
      changeBgColorLeft() {
        this.backgroundColorTWo = "#EC027C";
        this.toggleBgImgOne = true;
        this.borderColorTwo = "";
      },
      resetBackgroundColorLeft() {
        this.backgroundColorTWo = "";
        this.toggleBgImgOne = false;
        this.borderColorTwo = "#4D4D4D";
      },
    },
  };
  </script>
  
  <style >
  
  
  .slick-mentor .slick-slide .file {
    filter: grayscale(100%);
     opacity: 0.5;
  }
   .slick-mentor .slick-current .file {
    filter: grayscale(0%);
   

  }
  .slick-mentor .slick-current .current {
    opacity: 1;

  }
  .slick-mentor .slick-slide .leftmode {
    opacity: 0;
    background-color: #000000b1;
  }
  .slick-mentor .slick-current .leftmode {
    opacity: 0.3;
    background-color: #000000b1;
    border-radius: 1rem;
  }

  
  </style>
  