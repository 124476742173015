<template>
    <div class="">
      <headerView />
      <div class="max-w-7xl mx-auto mt-[3rem] lg:mt-[8rem] pl-5 pb-5">
        <div class="pb-5">
          <p class="text-[#0CB1EF] md:text-black text-[14px] hidden lg:block">
            <a href="/" class="text-[#EC027C]">Home</a>
            <span class="text-[#EC027C]"> &nbsp;/&nbsp;</span>
            <a class="text-[#4D4D4D]">Privacy Policy</a>
          </p>
        </div>
   
    <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base">We respect the privacy of those who visit our web site. In accordance with this, we have created this policy in order to demonstrate our privacy commitment to our users:</div>
    <div class="w-full text-left">      
        <h2 class="text-[#18479E] text-[20px] lg:text-[30px] font-mediumwhitespace-nowrap">What this Privacy Policy Covers</h2>

        <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base">This Privacy Policy covers our treatment of personally identifiable information that we collect when you are on our site, and when you use our services. This policy also covers our treatment of any personally identifiable information that third parties share with us.</div>

        <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base">This policy does not apply to the practices of organizations that we do not own or control, or to people that we do not employ or manage </div>

        <h2 class="text-[#18479E] text-[20px] lg:text-[30px] font-mediumwhitespace-nowrap">Information Collection and Use</h2>

        <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base">We collect personally identifiable information when you register on our website, when you use our services, and when you visit our pages. We may also receive personally identifiable information from third parties.</div>

        <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base">When you register with us, we ask for your name, email address, zip code, occupation, industry, and personal interests. Once you register with us and sign in to our services, you are not anonymous to us.
        </div>
        <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base">We use this information for three general purposes: to customize the content you see, to fulfill your requests for certain services, and to contact you about services.</div>
        <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base">We also automatically receive and record information on our server logs from your browser including your IP address, cookie information and the page you requested. This information is not linked to your account and will not be used to identify you.</div>





        <h2 class="text-[#18479E] text-[20px] lg:text-[30px] font-mediumwhitespace-nowrap ">Information Sharing and Disclosure</h2>

        <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base">We will not sell or rent your personally identifiable information to anyone.</div>

        <h2 class="text-[#18479E] text-[20px] lg:text-[30px] font-mediumwhitespace-nowrap ">We will send personally identifiable information about you to other companies or people when</h2>

        <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base">We have your consent to share the information;</div>
        <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base">We respond to subpoenas, court orders or legal process; or</div>
        <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base">We find that your actions on our web sites violate the Terms of Service</div>


        <h2 class="text-[#18479E] text-[20px] lg:text-[30px] font-mediumwhitespace-nowrap ">Changes to this Privacy Policy</h2>

        <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base">The privacy policy is subject to modification from time to time. If we decide to change our privacy policy, we will post those changes here so that you will always know what information we gather, how we might use that information and whether we will disclose it to anyone. Any significant changes to our privacy policy will be announced on our home page. If you do not agree with the changes in our policy you can simply discontinue to visit our website.
        </div>

        <h2 class="text-[#18479E] text-[20px] lg:text-[30px] font-mediumwhitespace-nowrap ">Questions or Suggestions</h2>

        <div class="my-4 text-start text-[#4D4D4D] md:text-black md:text-xl md:leading-[1.5rem] md:font-base">If you have questions or suggestions send an email to us.</div>

                
      
    </div>
</div>
  
      <footerView />
      <stickyView />
    </div>
  </template>
  
  <script>
  import headerView from "@/components/common/header.vue";
  import footerView from "@/components/common/footer.vue";
  import stickyView from "@/components/common/sticky.vue";
  export default {
    name: "PrivacyPolicy",
    components: {
      headerView,
      footerView,
      stickyView,
    },
  };
  </script>
  
  <style></style>
  