<template>
    <div>
      <div>
        <!-- header start-->
        <section class="w-full h-full">
          <div class="bg-[#ffff] py-4 lg:py-4 px-5 border-b-2 border-gray-400">
            <div class="w-full max-w-7xl mx-auto">
              <a :href="`tel:+91${programData.phone_number}`">
                <div class="hidden md:flex justify-between items-center">
                  <img
                    src="@/assets/lpProgramm/sharda-Online-Logo.png"
                    alt="Sharda Online"
                    srcset=""
                    class=""
                  />
                  <p
                    class="text-[#123177] py-3 text-[20px] rounded-3xl flex gap-2 items-center"
                  >
                    <img
                      src="@/assets/lpProgramm/phonecall.png"
                      alt="call-icon"
                      class="w-[30px] h-[30px]"
                    />
                    +91 {{ this.programData.phone_number }}
                  </p>
                </div>
              </a>
              <div class="flex justify-center items-center md:hidden">
                <img
                  src="@/assets/lpProgramm/sharda-Online-Logo.png"
                  alt=""
                  srcset=""
                  class=""
                />
              </div>
            </div>
          </div>
        </section>
        <!-- header end-->
        <section v-if="fullBanner == true" class="lg:border-b-2 lg:border-gray-400">
          <div class="bg-white lg:h-[500px] xl:h-[560px] md:px-4 2xl:p-0">
            <!-- Desktop Banner -->
            <div class="w-full max-w-7xl lg:h-[500px] xl:h-[560px] mx-auto hidden lg:block relative">
              <!-- content -->
  
              <!-- desktop banner image -->
              <div class="relative w-full h-full">
                <img :src="programData.banner" alt="" class="w-full h-full" srcset="" />
              </div>
  
              <!--Enquire form -->
              <div
                class="absolute right-0 top-0 z-20 hidden lg:flex justify-center items-center w-[330px] h-full xl:w-[400px]">
                <div
                    class="lg:w-[350px] h-[182px] bg-[#cfd5e4] p-[40px] font-semibold text-[18px] lg:text-[20px]"
                  >
                    <strong>Thank you</strong> for your enquiry, Our counselor will
                    get in touch with you shortly.
                  </div>
              </div>
            </div>
  
            <!-- Mobile Banner -->
            <div class="w-full lg:hidden px-5">
              <img :src="programData.mobile_banner" alt="" srcset="" class="w-full h-full" />
              <!-- <img
                src="@/assets/SO-LP-Desktop.jpg"
                alt=""
                srcset=""
                class="w-full h-full hidden md:block"
              /> -->
              <!--Enquire form -->
              <div class="w-full mt-5 py-4">
                <!-- <div class="flex flex-col justify-center items-center py-2">
                  <h1 class="text-lg text-white font-bold text-left">
                    Admissions Open 2024
                  </h1>
                  <p class="text-sm text-left text-white">
                    Batch Start: July 2024
                  </p>
                </div> -->
                <div class="py-[50px] lg:py-[150px] px-5 lg:px-0">
                <div
                    class="lg:w-[350px] h-[182px] bg-[#cfd5e4] p-[40px] font-semibold text-[18px] lg:text-[20px]"
                  >
                    <strong>Thank you</strong> for your enquiry, Our counselor will
                    get in touch with you shortly.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section v-else class="lg:border-b-2 lg:border-gray-400">
          <div class="bg-white lg:h-[560px] md:px-4 2xl:p-0">
            <!-- Desktop Banner -->
            <div
              class="w-full max-w-7xl lg:h-[560px] mx-auto hidden lg:block relative"
            >
              <div class="lg:flex lg:justify-between items-center h-full px-5">
                <!-- content -->
                <div class="flex flex-col gap-8 w-full">
                  <div class="flex flex-col gap-4">
                    <h1
                      class="lg:text-[30px] xl:text-[35px] text-[#17479e] text-[24px] leading-tight md:text-left font-bold"
                    >
                      {{ this.programData.title }}
                    </h1>
                    <p
                      class="text-left text-[18px] bg-[#17479e] w-[390px] p-2 text-white"
                    >
                      {{ programData.program_name }}
                    </p>
                  </div>
  
                  <ul class="text-black flex flex-col gap-1 px-5 md:px-0">
                    <li v-for="list in programData.banner_list" :key="list">
                      <div class="flex gap-2 items-center">
                        <img
                          src="@/assets/lpProgramm/play.png"
                          alt=""
                          srcset=""
                          class="w-5 h-5"
                        />
                        <p class="font-bold text-lg">{{ list }}</p>
                      </div>
                    </li>
                  </ul>
  
                  <!-- <div class="flex gap-4">
                  <div class="flex items-center">
                    <span
                      ><img
                        src="@/assets/lp-program/Untitled.webp"
                        alt="ugcweb"
                        class="w-[60px] h-[60px]" /></span
                    ><span
                      class="text-sm font-bold w-[5rem] md:text-black text-left"
                      >NAAC A+ Accredited</span
                    >
                  </div>
                  <div class="flex items-center">
                    <span
                      ><img
                        src="@/assets/programInfo/ugc.webp"
                        alt="ugcweb"
                        class="w-[60px] h-[60px]" /></span
                    ><span
                      class="text-sm font-bold w-[5rem] md:text-black text-left"
                      >UGC ENTITLED</span
                    >
                  </div>
                </div> -->
  
                  <h1 class="text-3xl text-black font-bold text-left">
                    Admissions Open 2024
                  </h1>
                  <p class="text-blacl text-lg text-left">
                    Batch Starts - July 2024
                  </p>
                </div>
  
                <!-- desktop banner image -->
                <div class="hidden lg:block absolute bottom-0 left-[37%]">
                  <img
                    :src="programData.banner"
                    alt=""
                    srcset=""
                    class="xl:w-[385px] md:h-[250px] lg:h-[455px] xl:h-[505px] object-contain"
                  />
                </div>
  
                <!-- desktop wedget -->
                <div class="py-[50px] lg:py-[150px] px-5 lg:px-0">
                  <div
                    class="lg:w-[350px] h-[182px] bg-[#cfd5e4] p-[40px] font-semibold text-[18px] lg:text-[20px]"
                  >
                    <strong>Thank you</strong> for your enquiry, Our counselor will
                    get in touch with you shortly.
                  </div>
                </div>
              </div>
            </div>
  
            <!-- Mobile Banner -->
            <div class="w-full lg:hidden">
              <div class="flex flex-col gap-3 py-4 px-5">
                <!-- content -->
                <div class="flex flex-col gap-4">
                  <h1 class="text-[25px] font-bold text-black">
                    {{ this.programData.title }}
                  </h1>
                  <p
                    class="text-left text-[14px] bg-[#17479e] w-full flex justify-center p-2 text-white"
                  >
                    {{ programData.program_name }}
                  </p>
                </div>
                <!-- <div class="mt-3 flex justify-center bg-[#17479e] rounded-md">
                <ul class="text-white" v-for="key in degrees" :key="key">
                  <p class="text-[12px] p-2">{{ key }}</p>
                </ul>
              </div> -->
                <!-- <div
                class="text-left text-[10px] bg-[#17479e] w-full flex justify-center p-2 text-white"
              >
                <span class="pr-2 border-r-2">MBA</span
                ><span class="pr-2 pl-2 border-r-2">MCA</span
                ><span class="pr-2 pl-2 border-r-2">M.COM</span
                ><span class="pr-2 border-r-2 pl-2">BBA</span
                ><span class="pr-2 border-r-2 pl-2">BCA</span
                ><span class="pr-2 border-r-2 pl-2">B.COM</span
                ><span class="pr-2 pl-2">BA(Hons.)</span>
              </div> -->
  
                <div>
                  <img
                    :src="programData.mobile_banner"
                    alt=""
                    srcset=""
                    class="w-[180px] h-full mx-auto"
                  />
                </div>
                <h1 class="text-black font-bold">UGC Entitled</h1>
                <p class="text-black font-bold">0 Cost EMI Option</p>
              </div>
  
              <!--Enquire form -->
              <div class="w-full mt-5 py-4 bg-[#123177]">
                <div class="flex flex-col justify-center items-center py-2">
                  <h1 class="text-lg text-white font-bold text-left">
                    Admissions Open 2024
                  </h1>
                  <p class="text-sm text-left text-white">
                    Batch Starts - July 2024
                  </p>
                </div>
                <!-- thankyou widget-->
  
                <div class="py-[50px] lg:py-[150px] px-5 lg:px-0">
                  <div
                    class="lg:w-[350px] h-[182px] bg-[#cfd5e4] p-[40px] font-semibold text-[18px] lg:text-[20px]"
                  >
                    <strong>Thank you</strong> for your enquiry, Our counselor
                    will get in touch with you shortly.
                  </div>
                </div>
                <!-- thankyou widget -->
              </div>
            </div>
          </div>
        </section>
      </div>
      <AboutView />
      <AwardsView />
      <OnlineProgram v-if="this.programData" :program="this.programData" />
      <HighightsView />
      <WhoApply v-if="this.programData" :program="this.programData" />
      <HiringView />
      <CareerSupport />
      <FaqView v-if="this.lpProgramfaq" :faq="this.lpProgramfaq" />
      <div class="pb-[80px] md:pb-0">
        <FooterView v-if="this.programData" :program="this.programData" />
      </div>
      <LpStickyView v-if="this.programData" :program="this.programData" />
    </div>
  </template>
  
  <script>
  import AboutView from "@/components/lpProgramm/AboutView.vue";
  import AwardsView from "@/components/lpProgramm/AwardsView.vue";
  import OnlineProgram from "@/components/lpProgramm/OnlineProgram.vue";
  import HighightsView from "@/components/lpProgramm/HighlightView.vue";
  import WhoApply from "@/components/lpProgramm/WhoApply.vue";
  import HiringView from "@/components/lpProgramm/HiringView.vue";
  import CareerSupport from "@/components/lpProgramm/CarrerSupport.vue";
  import FaqView from "@/components/lpProgramm/FaqView.vue";
  import FooterView from "@/components/lpProgramm/FooterView.vue";
  import LpStickyView from "@/components/lpProgramm/LpStickyView.vue";
  import axios from "axios";
  import { useHead } from "unhead";
  
  export default {
    name: "lpProgrammThankyou",
    components: {
      AboutView,
      AwardsView,
      OnlineProgram,
      HighightsView,
      WhoApply,
      HiringView,
      CareerSupport,
      FaqView,
      FooterView,
      LpStickyView,
    },
    data() {
      return {
        programData: [],
        lpProgramfaq: [],
        status: false,
        fullBanner: false,
      };
    },
    props: ["slug"],
    created() {
      if (this.slug == "online-degree-programs") {
        this.fullBanner = true;
      }
      this.programInfo();
      this.programFaq();
      console.log(this.slug, "online-degree-course-india");
      if (this.slug == "online-degree-course-india") {
        useHead({
          script: [
            {
              innerHTML: `gtag('event', 'conversion', {'send_to': 'AW-16612943417/X1CxCLqIoLsZELnM1fE9'});`,
            },
          ],
        });
      }
    },
    methods: {
      async programInfo() {
        await axios
          .get(`${process.env.VUE_APP_API}/lp/electives/?slug=${this.slug}`)
          .then((resp) => {
            if (resp.data.status == 200) {
              this.programData = resp.data.data[0];
              this.status = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async programFaq() {
        await axios
          .get(`${process.env.VUE_APP_API}/lp/electives/faq`)
          .then((resp) => {
            if (resp.data.status == 200) {
              this.lpProgramfaq = resp.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  };
  </script>
  
  <style></style>
  