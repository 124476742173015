<template>
  <div class="bg-gradient-to-b from-[#FFFFFF] to-[#EFEFEF] py-10">
    <div class="max-w-7xl m-auto">
      <!--mobile desgin-->
      <div class="md:hidden">
        <!--1st carousel-->
        <div class="bannerCarousel">
          <div
            class="flex flex-col lg:p-[1.5rem] "
            v-for="(el, index) in splitBannerData"
            :key="index"
          >
            <div class="">
              <img :src="el.bannerImg" alt="bannerImage" />
            </div>
            <div>
              <div
                class="pt-4 text-4xl font-bold text-[#18479E]"
                v-html="el.heading"
              ></div>
              <div
                class="text-[#0CB1EF] text-[55px] w-max mt-[-4%]"
                style="
                  font-family: northCost;
                  font-style: italic;
                  line-height: 1;
                  letter-spacing: 3.5px;
                "
              >
                {{ el.subHeading }}
               
              </div>
              <div class="pl-1 text-[#18479E] font-bold text-xl">
                {{ el.tags }}
              </div>
             
              <div class="pl-1 font-extrabold pt-3 text-[14px]">
                {{ el.degreename }}
              </div>
              <div
                class="text-white font-extrabold text-[16px] bg-[#ec027c] w-[11rem] pl-2"
              >
                {{ el.date }}
              </div>
              <div class=" pl-1 font-extralight pt-3 h1">
                {{ el.degrees }}
              </div>
              <!-- <div class="text-[#18479E] pl-1 font-bold">
                {{ data.degreeOffered }}
              </div> -->
              <div class="text-[#18479E] pl-1 font-bold">
                <ul class="flex flex-row justify-evenly gap-1">
    <li v-for="degree in el.degreeOffered" :key="degree.title">
      <a :href="degree.link">{{ degree.title }}</a>
     
    </li>
  </ul>
        </div>
              <a href="https://apply.shardaonline.ac.in/" target="_blank">
                <div
                  class="rounded-full border border-[#EC027C] w-max px-8 py-2 text-[#EC027C] mt-4 ml-1"
                >
                  Apply Now
                </div>
              </a>
            </div>
          </div>
        </div>
        <!--1st carousel-->
        <!--2nd carousel-->
        <div class="ssm:ml-10 ml-8 mt-4 mb-4">
          <div
            class="bg-[#0CB1EF] ssm:w-[15rem] w-[20rem] h-[7rem] rounded-tl-lg rounded-br-lg shadow-lg"
          >
            <div class="ml-auto w-max px-2 py-1">
              <img
                src="@/assets/home/header/cross.png"
                alt="cross"
                class="w-[.7rem]"
              />
            </div>
            <div class="banner_second_carousel ssm:bottom-[-5%]">
              <div
                class="flex flex-col pl-2"
                v-for="(el, index) in programmsMob"
                :key="index"
              >
                <a :href="`/${el.slug}/${el.short_slug}`">
                  <div
                    class="text-white font-medium ssm:text-[14px] px-4 opacity-90"
                  >
                    {{ el.programms }}
                  </div>
                  <div class="flex gap-x-4 items-center mt-2">
                    <div class="w-[1.5rem]">
                      <img :src="el.img" alt="el.title" />
                    </div>
                    <div class="text-white font-bold ssm:text-[14px] text-[15px] w-max">
                      {{ el.title }}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--2nd carousel-->
      <!--mobile desgin-->
      <!--desktop desging-->
      <div class="relative mt-2 hidden md:block">
        <!--1st carousel-->
        <div class="bannerCarousel">
          <div
            class="!flex justify-around items-center"
            v-for="(el, index) in splitBannerDataDesk"
            :key="index"
          >
            <div class="animate-text">
              <div
                class="text-4xl lg:text-5xl xl:text-6xl text-[#18479E] font-bold"
                v-html="el.heading"
              ></div>
              <div
                class="text-[50px] lg:text-[80px] xl:text-[100px] text-[#0CB1EF] mt-[-5%]"
                style="
                  font-family: northCost;
                  font-style: italic;
                  letter-spacing: 7px;
                  line-height: 1;
                "
              >
                {{ el.subHeading }}
              </div>
              <div class="text-2xl lg:text-3xl font-bold text-[#18479E] pb-4">
                {{ el.tags }}
              </div>
            
              <div class="text-[#18479E] font-bold text-[22px] pb-2">
                {{ el.degreename }}
              </div>
              <div
                class="text-white font-extrabold text-[20px] bg-[#ec027c] w-[13rem] pl-1 whitespace-nowrap"
              >
                {{ el.date }}
              </div>
              <h1 v-if="el.degrees === 'Online Degrees Offered'" class="text-[#18479E] font-bold text-[26px]">{{ el.degrees }}</h1>
  <h2 v-else class="text-[#18479E] font-bold text-[26px]">{{ el.degrees }}</h2>
              <!-- <div
                v-html="el.degreeOffered"
                class="text-[#18479E] font-bold text-[25px]"
              ></div> -->
              <div class="text-[#18479E] gap-3 font-bold text-[25px]">
                <ul class="flex gap-4">
    <li v-for="degree in el.degreeOffered" :key="degree.title">
      <a :href="degree.link">{{ degree.title }}</a>
      <span v-if="index < el.degreeOffered.length - 1"> | </span>
    </li>
  </ul>
    </div>
              <!-- <ul>
            <li v-for="(degree, index) in data.degreeOfferedArray" :key="index">
              <a :href="getDegreeLink(degree)" target="_blank">
                {{ degree }}
              </a>
            </li>
          </ul> -->
              
              <a href="https://apply.shardaonline.ac.in/" target="_blank">
                <div
                  class="rounded-full border border-[#EC027C] w-max px-8 py-2 text-[#EC027C] mt-4 ml-1 bg-white cursor-pointer"
                >
                  Apply Now
                </div>
              </a>
            </div>
            <div class="flex items-end">
              <div class="w-[5rem] lg:w-[10rem]">
                <img
                  src="@/assets/home/header/bannerPatelDesk.webp"
                  alt="bannerPattel"
                />
              </div>
              <div class="w-[23rem] lg:w-[26rem] xl:w-[32rem]">
                <img :src="el.bannerImg" alt="el.degree" />
              </div>
            </div>
          </div>
        </div>
        <!--1st carousel-->

        <!--2nd carousel-->
        <div class="absolute bottom-[-5%] right-[5%] xl:bottom-0 xl:right-0">
          <div class="ssm:ml-10 ml-8 mt-4 mb-4">
            <div
              class="bg-[#0CB1EF] ssm:w-[15rem] w-[20rem] h-[8rem] rounded-tl-xl rounded-br-xl shadow-lg"
            >
              <div class="banner_second_desktop_carousel ssm:bottom-[-5%]">
                <div
                  class="flex justify-center flex-col pl-2"
                  v-for="(el, index) in programms"
                  :key="index"
                >
                  <a :href="`/${el.slug}/${el.short_slug}`">
                    <div class="text-white ssm:text-sm text-center px-4 pt-6">
                      {{ el.programms }}
                    </div>
                    <div class="flex justify-center item-center mt-2">
                      <div
                        class="text-white font-bold ssm:text-[20px] w-[max m-auto flex items-center gap-x-4"
                      >
                        <img :src="el.img" alt="el.title" class="w-[2rem]" />
                        <span v-html="el.title"></span>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div
                class="prev-rank absolute top-[51%] left-[11%] cursor-pointer"
              >
                <img
                  src="@/assets/home/header/second_banner_left.png"
                  width="30px"
                  height="30px"
                  alt=""
                />
              </div>
              <div
                class="next-rank absolute top-[51%] right-[5%] cursor-pointer"
              >
                <img
                  src="@/assets/home/header/second_banner_right.png"
                  width="30px"
                  height="30px"
                  alt=""
                  class=""
                />
              </div>
            </div>
          </div>
        </div>
        <!--2nd carousel-->
      </div>
      <!--desktop desging-->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";

export default {
  name: "bannerView",
  data() {
    return {
      bannerData: [
        {
          bannerImg: require("@/assets/home/header/banner_img_mobile.webp"),
          heading: "THE WORLD <br/> IS NOW ONLINE",
          subHeading: "Where are you?",
          tags: "#RahoHarKadamAage",
          degrees: "Online Degrees Offered:",
          degreename: "MBA enrolments for July'24 intake are now closed.",
          // date: "4th August 2024",
          degreeOffered: "MBA1 | M.Com | MCA | BBA | BCA | BA(Hons.)",
        },
        // {
        //   bannerImg: require("@/assets/home/header/bannermobile-1.webp"),
        //   heading: "YOUR ROAD TO <br/> ",
        //   subHeading: "Success begins here!",
        //   tags: "#RahoHarKadamAage",
        //   degreename: "Batch starts on:",
        //   date: "28th September 2024",
        //   degrees: "Online Degrees Programs:",
        //   degreeOffered: "MBA | M.Com | MCA | BBA | BCA | BA(Hons.) ",
        // }, 
        // {
        //   bannerImg: require("@/assets/home/header/banner_img_mobile.png"),
        //   heading: "THE WORLD <br/> IS NOW ONLINE",
        //   subHeading: "Where are you?",
        //   tags: "#RahoHarKadamAage",
        //   degrees: "Online Degrees Offered",
        //   degreeOffered: "Master’s: MBA | M.Com | MCA ",
        // },
      ],
      bannerDataDesk: [
        {
          bannerImg: require("@/assets/home/header/online-degree-shardaonline.webp"),
          heading: "THE WORLD <br/> IS NOW ONLINE",
          subHeading: "Where are you?",
          tags: "#RahoHarKadamAage",
          degrees: "Online Degrees Offered:",
          degreename: "MBA enrolments for July'24 intake are now closed.",
          // date: "4th August 2024",
          degreeOffered: "MBA | M.Com | MCA | BBA | BCA | BA(Hons.) ",
        },
        // {
        //   bannerImg: require("@/assets/home/header/bannerdesktop-1.webp"),
        //   heading: "YOUR ROAD TO <br/> ",
        //   subHeading: "Success begins here!",
        //   tags: "#RahoHarKadamAage",
        //   degreename: "Batch starts on:",
        //   date: "28th September 2024",
        //   degrees: "Online Degrees Programs:",
        //   degreeOffered: "MBA | M.Com | MCA | BBA | BCA | BA(Hons.) ",
        // },
        // {
        //   bannerImg: require("@/assets/home/header/bannerDesktop.png"),
        //   heading: "THE WORLD <br/> IS NOW ONLINE",
        //   subHeading: "Where are you?",
        //   tags: "#RahoHarKadamAage",
        //   degrees: "Online Degrees Offered",
        //   degreename: "Master’s:",
        //   degreeOffered: " MBA | M.Com | MCA ",
        // },
      ],
      programms: [
        {
          img: require("@/assets/home/header/MBA-in-Finance.webp"),
          title: "MBA in Finance",
          programms: "Our Flagship Programs",
          slug: "online-mba",
          short_slug: "finance",
        },
        {
          img: require("@/assets/home/header/MBA-in-Marketing.webp"),
          title: "MBA in Marketing",
          programms: "Our Flagship Programs",
          slug: "online-mba",
          short_slug: "marketing",
        },
        {
          img: require("@/assets/home/header/Untitled-1.webp"),
          title: "MBA in Human <br> Resource Management",
          programms: "Our Flagship Programs",
          slug: "online-mba",
          short_slug: "human-resource-management",
        },
        {
          img: require("@/assets/home/header/MBA-in-Data-Science-and-Analytics.webp"),
          title: "MBA in Data Science <br> and Analytics",
          programms: "Our Flagship Programs",
          slug: "online-mba",
          short_slug: "data-science-and-analytics",
        },
        {
          img: require("@/assets/home/header/MBA-in-Project-Management.webp"),
          title: "MBA in Project <br> Management",
          programms: "Our Flagship Programs",
          slug: "online-mba",
          short_slug: "project-management",
        },
      ],
      programmsMob: [
        {
          img: require("@/assets/home/header/MBA-in-Finance.webp"),
          title: "MBA in Finance",
          programms: "Our Flagship Programs",
          slug: "online-mba",
          short_slug: "finance",
        },
        {
          img: require("@/assets/home/header/MBA-in-Marketing.webp"),
          title: "MBA in Marketing",
          programms: "Our Flagship Programs",
          slug: "online-mba",
          short_slug: "marketing",
        },
        {
          img: require("@/assets/home/header/Untitled-1.webp"),
          title: "MBA in Human  Resource Management",
          programms: "Our Flagship Programs",
          slug: "online-mba",
          short_slug: "human-resource-management",
        },
        {
          img: require("@/assets/home/header/MBA-in-Data-Science-and-Analytics.webp"),
          title: "MBA in Data Science  and Analytics",
          programms: "Our Flagship Programs",
          slug: "online-mba",
          short_slug: "data-science-and-analytics",
        },
        {
          img: require("@/assets/home/header/MBA-in-Project-Management.webp"),
          title: "MBA in Project  Management",
          programms: "Our Flagship Programs",
          slug: "online-mba",
          short_slug: "project-management",
        },
      ],
    };
  },

  computed: {
    splitBannerData() {
      return this.bannerData.map(item => ({
        ...item,
        degreeOffered: this.getDegreeLinks(item.degreeOffered)
      }));
    },
    splitBannerDataDesk() {
      return this.bannerDataDesk.map(item => ({
        ...item,
        degreeOffered: this.getDegreeLinks(item.degreeOffered)
      }));
    }
  },
  methods: {
    getDegreeLinks(degrees) {
      // Define specific links for each degree
      const degreePaths = {
        "mba": "/masters-business-administration-online-degree",
        "mcom": "/master-of-commerce-online-degree",
        "mca": "/masters-computer-applications-online-degree",
        "bba": "/bachelors-business-administration-online-degree",
        "bca": "/bachelor-computer-application-online-degree",
        "bahons": "/bachelor-of-arts-online-degree"
      };

      // Split degrees and map each to an object with a title and a specific link
      return degrees.split(" | ").map(degree => {
        // Normalize the degree name for matching
        const normalizedDegree = degree.toLowerCase().replace(/[^a-z0-9]/g, '');
        return { title: degree, link: degreePaths[normalizedDegree] || '#' };
      });
    }
  },

  mounted() {
    $(`.bannerCarousel`).slick({
      infinite: true,
      autoplay: true,
      prevArrow: null,
      nextArrow: null,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      dotsClass: "slick-dots",
    });
    $(`.banner_second_carousel`).slick({
      infinite: true,
      autoplay: true,
      prevArrow: null,
      nextArrow: null,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      dotsClass: "banner_second_dots_mob",
    });
    $(`.banner_second_desktop_carousel`).slick({
      infinite: true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      dotsClass: "banner_second_dots",
      nextArrow: $(".next-rank"),
      prevArrow: $(".prev-rank"),
    });
  },
};
</script>

<style>
.slick-dots {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2rem;
  padding: 0;
  list-style-type: none;
}

.slick-dots li {
  margin: 0 0.25rem;
}

.slick-dots button {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  padding: 0;
  margin: 5px;
  border: none;
  border-radius: 100%;
  background-color: #18479e;
  text-indent: -9999px;
}

.slick-dots li.slick-active button {
  background-color: #ec027c;
  width: 0.5rem;
  height: 0.5rem;
}
.banner_second_dots_mob {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2rem;
  padding: 0;
  list-style-type: none;
  position: absolute;
  bottom: -18%;
  left: 30%;
}
.banner_second_dots_mob {
  margin: 0 0.25rem;
}
.banner_second_dots_mob button {
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  padding: 0;
  margin: 5px;
  border: none;
  border-radius: 100%;
  background-color: silver;
  text-indent: -9999px;
}

.banner_second_dots_mob li.slick-active button {
  background-color: white;
  width: 0.3rem;
  height: 0.3rem;
}
.banner_second_dots {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2rem;
  padding: 0;
  list-style-type: none;
  position: absolute;
  bottom: -30%;
  left: 30%;
}

.banner_second_dots li {
  margin: 0 0.25rem;
}

.banner_second_dots button {
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  padding: 0;
  margin: 5px;
  border: none;
  border-radius: 100%;
  background-color: silver;
  text-indent: -9999px;
}
.h1{
font-size: 14px;
}

.banner_second_dots li.slick-active button {
  background-color: white;
  width: 0.3rem;
  height: 0.3rem;
}
@media (min-width: 1024px) {
  .banner_second_dots {
    bottom: -15px; /* Adjust bottom positioning */
    left: 30%; /* Adjust horizontal positioning */
  }
}
@media (min-width: 1440px) {
  .banner_second_dots {
    bottom: -20px; /* Adjust bottom positioning */
    left: 30%; /* Adjust horizontal positioning */
  }
}
.animate-text {
  animation: animateIn 1.5s ease forwards;
  animation-delay: 0.5s; /* Adjust delay as needed */
}
@keyframes animateIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
