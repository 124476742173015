<template>
  <div>
    <headerView />
    <!--bannerViewMobile-->
    <div
      class="flex flex-col justify-center items-center bg-gradient-to-b from-white to-[#EFEFEF] lg:hidden"
    >
      <div class="mt-[5rem] ssm:px-2">
        <div class="video-container2 leaf-video">
          <video
            autoplay
            muted
            loop
            class="leaf"
            src="https://www.sharda.ac.in/attachments/common_files/home-banner-vid3.mp4"
            poster="@/assets/about/leaf.webp"
            title="video player"
          ></video>
        </div>
      </div>
      <div
        class="w-[18rem] my-8 text-[#18479E] text-[40px] font-bold self-start md:self-center ssm:ml-0 ml-12 md:ml-0 ssm:px-2"
      >
        <div>Empowering</div>
        <div>Students</div>
        <div>through the</div>
        <div class="text-[#EC027C] lg:hidden">Power of Online Education</div>
      </div>
    </div>
    <!--bannerViewMobile-->
    <!--banner desktop-->
    <div class="bg-gradient-to-b from-white to-[#EFEFEF]">
      <div class="max-w-7xl m-auto">
        <div
          class="hidden lg:flex lg:justify-around lg:items-center mt-[8rem] lg:p-4 xl:p-0"
        >
          <div
            class="text-5xl font-bold w-[40rem] leading-[4rem] text-[#18479E]"
          >
            Empowering Students through the
            <span class="text-[#EC027C]"
              >Power of <br />
              Online Education</span
            >
          </div>
          <div class="w-[30rem] pb-8 leaf-video">
            <div class="video-container">
              <video
                autoplay
                muted
                loop
                class="leaf"
                src="https://www.sharda.ac.in/attachments/common_files/home-banner-vid3.mp4"
                poster="@/assets/about/leaf.webp"
                title="video player"
              ></video>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--banner desktop-->
    <!--about ShardaOnline-->
    <div class="my-4 md:my-10">
      <div class="max-w-7xl mx-auto">
        <div class="max-w-6xl mx-auto px-5">
          <div
            class="ssm:ml-4 text-3xl md:text-5xl font-medium w-max text-[#18479E]"
          >
            About <span class="text-[#EC027C]">Sharda Online</span>
          </div>
          <div
            class="w-full my-4 ssm:ml-4 text-start text-[#4D4D4D] md:text-black md:text-2xl md:leading-[2.5rem] md:font-base"
          >
            At Sharda University’s Centre for Distance and Online Education
            (CDOE) we believe in equipping our students with not only academic
            knowledge, but also the necessary skills required to overcome
            challenges, navigate change, and create opportunities in their
            respective industries. Empowered by the might of technology and the
            reach of the internet, the programs at Sharda University CDOE train
            students to approach business obstacles and solve them like
            established corporations.
          </div>
        </div>
      </div>
    </div>
    <!--about ShardaOnline-->
    <!--our leadership-->
    <leaderViewVue />
    <!--our leadership-->
    <!--awardVue-->
    <awardAclade />
    <!--awardVue-->
    <!--technology-->
    <ourTechnology />
    <!--technology-->
    <!--aluminai work-->
    <aluminaiwork />
    <!--aluminai work-->
    <footerView />
    <stickyView />
  </div>
</template>

<script>
import headerView from "@/components/common/header.vue";
import footerView from "@/components/common/footer.vue";
import leaderViewVue from "@/components/about/leaderView.vue";
import awardAclade from "@/components/about/awardAclade.vue";
import ourTechnology from "@/components/about/ourTechnology.vue";
import aluminaiwork from "@/components/home/aluminaiwork.vue";
import stickyView from "@/components/common/sticky.vue";

export default {
  name: "aboutView",
  components: {
    headerView,
    footerView,
    leaderViewVue,
    awardAclade,
    ourTechnology,
    aluminaiwork,
    stickyView,
  },
};
</script>

<style scoped>
.video-container {
  width: 400px;
  height: 400px;
  border-radius: 280px 0;
  overflow: hidden;
  position: relative;
}

.leaf {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
}
.leaf-video {
	background-image: url('@/assets/about/leaf.webp');
	width: 429px;
	height: 386px;
	border-radius: 278px 0;
	overflow: hidden;
	position: relative;
	padding: 15px;
	margin-bottom: 50px;
	padding-top: 28px;
}
.video-container2 {
  width: 300px;
  height: 300px;
  border-radius: 210px 0;
  overflow: hidden;
  position: relative;
}
</style>
