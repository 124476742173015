<template>
  <div>
    <headerView />
    <BannerView :elective="Electives" ref="banner" />
    <div v-if="showNewHeaderMobile" class="newHeaderMobile lg:hidden">
      <div class="max-w-7xl m-auto">
        <div class="flex items-center justify-around">
          <div
            class="flex gap-x-4 overflow-x-auto whitespace-nowrap navbarMob"
            ref="navbarMob"
          >
            <div
              v-for="el in programData"
              :key="el.id"
              class="font-medium cursor-pointer pb-4 text-xs"
              @click="
                navBarActive(el.name);
                selectedId = el.id;
              "
              :tabId="el.name"
              :class="{ active: this.activeSection === el.name }"
            >
              {{ el.name }}
            </div>
          </div>
          <!-- <a href="https://apply.shardaonline.ac.in/" target="_blank">
            <div
              class="mt-4 cursor-pointer bg-[#EC027C] rounded-full py-3 lg:w-[8rem] xl:w-[10rem] text-center text-white font-medium mb-8"
            >
              Apply Now
            </div>
          </a> -->
        </div>
      </div>
    </div>
    <div v-if="showHeader" class="newHeader hidden lg:block">
      <div class="max-w-7xl m-auto">
        <div class="flex items-center justify-around">
          <button @click="slideLeft" id="slide_left" class="slide_arrow px-5">
            &#10094;
          </button>
          <div
            class="flex items-center gap-x-6 xl:gap-x-10 mt-4 w-[90%] whitespace-nowrap scroll-smooth overflow-x-auto no-scroll navbar"
            id="slider"
            ref="navbar"
          >
            <div
              v-for="el in programData"
              :key="el.id"
              class="font-medium cursor-pointer pb-4 text-sm xl:text-md nav-item"
              @click="
                navBarActive(el.name);
                selectedId = el.id;
              "
              :tabId="el.name"
              :class="{ active: this.activeSection === el.name }"
            >
              {{ el.name }}
            </div>
          </div>
          <button @click="slideRight" id="slide_right" class="slide_arrow px-5">
            &#10095;
          </button>
          <a href="https://apply.shardaonline.ac.in/" target="_blank">
            <div
              class="mt-4 cursor-pointer bg-[#EC027C] rounded-full py-3 lg:w-[8rem] xl:w-[10rem] text-center text-white font-medium mb-8">
              Apply Now
            </div>
          </a>
        </div>
      </div>
    </div>

    <ProgramOver :elective="programOverView" id="Overview" />
    <ProgramHighlight v-if="Electives.new_highlights" :highlight_list="Electives.new_highlights" id="Highlights" />
    <FeesView :elective="Electives" id="Fees" />
    <CricullumView
      v-if="Electives"
      :elective="Electives"
      class="hidden lg:block"
      id="Curriculum"
    />
    <!-- <GetMentoredView
      id="Faculty"
      v-if="this.Electives"
      :facultyToShow="this.Electives.short_slug"
    /> -->
    <GetMentoredView id="Faculty" v-if="Electives" :faculty="Electives.program" />
    <div class="flex flex-col" id="Why Us">
      <div>
        <WhySharda />
      </div>
      <div class="flex justify-center bg-gradient-to-b from-white to-[#EFEFEF] py-4 pb-[40px]">
        <div class="flex flex-col gap-4 m-auto max-w-7xl">
          <h2 class="text-[#18479E] text-[18px] lg:text-[30px] text-center font-medium">
            So, why wait?
            <span class="text-[#EC027C]">An unmatched online <br />
              learning experience
            </span>
            awaits you!
          </h2>
          <div class="flex justify-center">
            <button
              class="lg:text-[16px] text-[#EC027C] border w-[200px] h-[50px] rounded-full border-[#EC027C] font-bold"
              @click="openModal">
              Enroll Now
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--enquire modal-->
    <div class="Emodal !z-50" v-if="isModalOpen">
      <div class="Emodal-content rounded-md relative top-[100px] lg:top-[-15%] xl:top-[-15%] md:w-[35%] 2xl:w-[30%]">
        <button class="close rounded-full" @click="closeModal">x</button>
        <enquireForm />
      </div>
    </div>
    <!--enquire modal-->
    <CricullumView v-if="Electives" :elective="Electives" class="block lg:hidden" id="Curriculum" />

    <ourTechnology class="py-10" id="Technology" />
    <!-- <LearningView id="LMS" /> -->
    <SpecialisationsView id="Other Specialisations" v-if="this.specialzation" :specialzation="specialzation" />
    <certificationVue id="Degree" />
    <!-- <OurStudentsView id="Testimonials" /> -->
    <WhoShouldView id="Who" :Electives="Electives" />
    <!-- <AlwaysView id="Always" /> -->
    <aluminaiwork id="Companies" />
    <PlacementSupport id="Placement Support" />
    <CareerView id="Career" />
    <EnrollView id="Enroll" />
    <enquireView id="FAQs" v-if="this.Electives.id" :electiveId="this.Electives.id" />
    <footerView />
    <stickyView />
  </div>
</template>

<script>
import axios from "axios";
import headerView from "@/components/common/header.vue";
import BannerView from "@/components/ElectiveDetail/BannerView.vue";
import ProgramOver from "@/components/ElectiveDetail/ProgramOver.vue";
import ProgramHighlight from "@/components/ElectiveDetail/ProgramHighlight.vue";
import GetMentoredView from "@/components/home/GetMentoredView.vue";
import WhySharda from "@/components/home/whySharda.vue";
import ourTechnology from "@/components/about/ourTechnology.vue";
import SpecialisationsView from "@/components/ElectiveDetail/SpecialisationsView.vue";
import certificationVue from "@/components/ElectiveDetail/certification.vue";
// import OurStudentsView from "@/components/ElectiveDetail/OurstudentsView.vue";
import WhoShouldView from "@/components/ElectiveDetail/WhoShouldView.vue";
// import AlwaysView from "@/components/ElectiveDetail/AlwaysView.vue";
import aluminaiwork from "@/components/home/aluminaiwork.vue";
import PlacementSupport from "@/components/ElectiveDetail/PlacementSupport.vue";
import CareerView from "@/components/ElectiveDetail/CareerView.vue";
import EnrollView from "@/components/ElectiveDetail/EnrollView.vue";
import enquireView from "@/components/ElectiveDetail/enquireView.vue";
import footerView from "@/components/common/footer.vue";
import FeesView from "../components/ElectiveDetail/FeesView.vue";
import CricullumView from "@/components/ElectiveDetail/CurriculumView.vue";
// import LearningView from "@/components/ElectiveDetail/LearningView.vue";
import enquireForm from "@/components/enquireNowForms/enquire.vue";
import stickyView from "@/components/common/sticky.vue";

export default {
  name: "ElectivePage",
  props: ["program_slug", "slug"],
  components: {
    headerView,
    FeesView,
    CricullumView,
    // LearningView,
    BannerView,
    ProgramOver,
    ProgramHighlight,
    GetMentoredView,
    WhySharda,
    ourTechnology,
    SpecialisationsView,
    certificationVue,
    // OurStudentsView,
    WhoShouldView,
    // AlwaysView,
    aluminaiwork,
    PlacementSupport,
    CareerView,
    EnrollView,
    enquireView,
    enquireForm,
    footerView,
    stickyView,
  },
  data() {
    return {
      Electives: [],
      showNewHeaderMobile: false,
      showHeader: false,
      navClick: false,
      programData: [
        { id: 1, name: "Overview" },
        { id: 2, name: "Highlights" },
        { id: 3, name: "Fees" },
        { id: 4, name: "Curriculum" },
        { id: 5, name: "Faculty" },
        { id: 6, name: "Why Us" },
        { id: 7, name: "Technology" },
        // { id: 8, name: "LMS" },
        { id: 9, name: "Other Specialisations" },
        { id: 10, name: "Degree" },
        // { id: 11, name: "Testimonials" },
        { id: 12, name: "Who" },
        // { id: 13, name: "Always" },
        { id: 14, name: "Companies" },
        { id: 15, name: "Placement Support" },
        { id: 16, name: "Career" },
        { id: 17, name: "Enroll" },
        { id: 18, name: "FAQs" },
      ],
      selectedId: null,
      programOverView: "",
      isModalOpen: false,
      allElectiveData: [],
      specialzation: [],
      activeSection: "",
    };
  },
  created() {
    this.electiveCall();
    this.allElective(this.program_slug);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleDesScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("scroll", this.handleDesScroll);
  },

  methods: {
    async electiveCall() {
      // const slug = this.$route.params.slug; // Get the slug from the route
      const apiUrl = `${process.env.VUE_APP_API}/course/elective-list/?program__short_slug=${this.program_slug}&&slug=${this.slug}`;
      try {
        const resp = await axios.get(apiUrl);
        if (resp.data.status === 200) {
          this.Electives = resp.data.data[0];
          // console.log(this.Electives.program, "here there");
          this.stripHtmlTags(this.Electives.description);
        } else {
          this.$router.push({
            name: "PageNotFound",
            params: { pathMatch: "page-not-found" },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async allElective(data) {
      await axios
        .get(
          `${process.env.VUE_APP_API}/course/elective-list/?short_slug=${data}`
        )
        .then((resp) => {
          this.allElectiveData = resp.data.data;
          this.specialzation = this.allElectiveData.filter(
            (item) => item.slug != this.slug
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleScroll() {
      const banner = this.$refs.banner?.$el;
      if (banner) {
        const bannerBottom = banner.getBoundingClientRect().bottom;
        this.showNewHeaderMobile = bannerBottom <= 0;
      }
      if (!this.navClick) {
        this.updateActiveNavMobile();
      }
    },
    handleDesScroll() {
      const banner = this.$refs.banner?.$el;
      if (banner) {
        const bannerBottom = banner.getBoundingClientRect().bottom;
        this.showHeader = bannerBottom <= 0;
      }
      if (!this.navClick) {
        this.updateActiveNavDesktop();
      }
    },
    navBarActive(tabId) {
      this.navClick = true;
      const targetElement = document.getElementById(tabId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - 80,
          behavior: "smooth",
        });
        setTimeout(() => {
          this.navClick = false;
        }, 1000);
      }
    },
    updateActiveNavMobile() {
      const sections = this.programData.map((section) => {
        const element = document.getElementById(section.name);
        return {
          id: section.name,
          offsetTop: element ? element.offsetTop : 0,
          offsetHeight: element ? element.offsetHeight : 0,
        };
      });

      const scrollPosition = window.scrollY + window.innerHeight / 2;

      for (let section of sections) {
        if (
          scrollPosition >= section.offsetTop &&
          scrollPosition < section.offsetTop + section.offsetHeight
        ) {
          this.activeSection = section.id;
          this.$nextTick(() => {
            // Ensure this runs after the DOM has been updated
            if (this.showNewHeaderMobile) {
              this.scrollNavbarToActiveSectionMob();
            }
          });
          break;
        }
      }
    },
    updateActiveNavDesktop() {
      const sections = this.programData.map((section) => {
        const element = document.getElementById(section.name);
        return {
          id: section.name,
          offsetTop: element ? element.offsetTop : 0,
          offsetHeight: element ? element.offsetHeight : 0,
        };
      });

      const scrollPosition = window.scrollY + window.innerHeight / 4;

      for (let section of sections) {
        if (
          scrollPosition >= section.offsetTop &&
          scrollPosition < section.offsetTop + section.offsetHeight
        ) {
          this.activeSection = section.id;
          this.$nextTick(() => {
            // Ensure this runs after the DOM has been updated
            if (this.showHeader) {
              this.scrollNavbarToActiveSection();
            }
          });
          break;
        }
      }
    },
    scrollNavbarToActiveSection() {
      const navbar = this.$refs.navbar;
      if (!navbar) {
        console.error("Navbar ref is not available.");
        return;
      }
      const activeElement = navbar.querySelector(".active");
      if (activeElement) {
        const navbarRect = navbar.getBoundingClientRect();
        const activeRect = activeElement.getBoundingClientRect();
        if (
          activeRect.left < navbarRect.left ||
          activeRect.right > navbarRect.right
        ) {
          activeElement.scrollIntoView({
            inline: "center",
            behavior: "smooth",
          });
        }
      }
    },
    scrollNavbarToActiveSectionMob() {
      const navbar = this.$refs.navbarMob;
      if (!navbar) {
        console.error("Navbar ref is not available.");
        return;
      }
      const activeElement = navbar.querySelector(".active");

      if (activeElement) {
        const navbarRect = navbar.getBoundingClientRect();
        const activeRect = activeElement.getBoundingClientRect();

        if (
          activeRect.left < navbarRect.left ||
          activeRect.right > navbarRect.right
        ) {
          activeElement.scrollIntoView({
            inline: "center",
            behavior: "smooth",
          });
        }
      }
    },
    slideRight() {
      const container = document.getElementById("slider");
      const scrollAmount = Math.max(container.clientWidth * 0.5, 90);
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    },
    slideLeft() {
      const container = document.getElementById("slider");
      const scrollAmount = Math.max(container.clientWidth * 0.5, 90);
      container.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    },
    stripHtmlTags(input) {
      if (typeof input === "string") {
        this.programOverView = input.replace(/<\/?[^>]+(>|$)/g, "");
      } else {
        this.programOverView = "";
      }
      return this.programOverView;
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style scoped>
.Emodal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Emodal-content {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  /* width: 500px; */
}

.close {
  position: relative;
  color: white;
  float: right;
  top: -10px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  background-color: blue;
  width: 1.4rem;
  height: 1.4rem;
}

@media (min-width: 1024px) {
  .no-scroll::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Edge */
  }

  .no-scroll {
    scrollbar-width: none;
  }
}

.active {
  border-bottom: 2px solid #ec027c; /* Change to your desired border color */
  color: #ec027c;
}
</style>
