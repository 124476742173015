<template>
  <div class="py-10 lg:py-8 px-5 lg:px-8" v-if="specialzation.length > 0">
    <div class="w-full max-w-6xl mx-auto h-[400px] lg:h-[550px]">
      <div class="flex flex-col gap-5">
        <h2 class="text-[30px] lg:text-[60px] font-normal text-center">
          <span class="text-[#18479E]">Other </span>
          <span class="text-[#EC027C]">Specialization</span>
        </h2>
        <!-- desktop -->
        <div class="hidden lg:block">
          <div class="flex flex-wrap w-full">
            <div
              v-for="special in specialzation"
              :key="special.id"
              class="flex flex-col items-center gap-4 w-[20%] py-3"
            >
              <a :href="`/${special.short_slug}/${special.slug}`">
                <div
                  class="border border-[#D1D1D1] rounded-full w-[118px] h-[118px] flex justify-center items-center"
                >
                  <img
                    :src="special.icons"
                    alt=""
                    srcset=""
                    class="w-[80px] h-[80px]"
                  />
                </div>
              </a>
              <a :href="`/${special.short_slug}/${special.slug}`">
                <p
                  class="text-[16px] font-medium text-center text-[#4D4D4D]"
                  v-html="special.name"
                ></p>
              </a>
            </div>
          </div>
        </div>
        <!-- mobile -->
        <div class="block lg:hidden">
          <div class="relative w-full">
            <div class="flex specialCarousel_mobile h-[270px]">
              <div
                v-for="special in specialzation"
                :key="special"
                class="w-full"
              >
                <div
                  class="flex flex-col justify-center items-center w-full h-[260px]"
                >
                  <a :href="`/${special.short_slug}/${special.slug}`">
                    <div
                      class="border border-[#D1D1D1] rounded-full w-[66px] h-[66px] flex justify-center items-center card_mobile"
                    >
                      <img
                        :src="special.icons"
                        alt=""
                        srcset=""
                        class="w-[36px] h-[36px] imag-hide"
                      />
                    </div>
                  </a>
                  <a :href="`/${special.short_slug}/${special.slug}`">
                    <p
                      class="text-xs w-full font-medium text-center text-[#4D4D4D] paraspecial h-[80px]"
                      v-html="special.name"
                    ></p>
                  </a>
                </div>
              </div>
            </div>
            <div
              class="border border-[#18479E] rounded-full p-3 left-special absolute bottom-[-40px] left-[100px]"
            >
              <img
                src="@/assets/home/affiliated/Icon feather-arrow-left@2x.png"
                alt=""
                srcset=""
                class="w-[16px] h-[16px]"
              />
            </div>
            <div
              class="border border-[#18479E] rounded-full p-3 right-special absolute bottom-[-40px] right-[100px]"
            >
              <img
                src="@/assets/home/affiliated/Icon feather-arrow-right@2x.png"
                alt=""
                srcset=""
                class="w-[16px] h-[16px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "SpecialisationsView",
  props: ["specialzation"],
  data() {
    return {};
  },
  updated() {
    if (this.specialzation.length > 0) {
      console.log(this.specialzation.length);
      this.initializespecial();
    }
  },
  methods: {
    initializespecial() {
      $(`.specialCarousel_mobile`).slick({
        infinite: true,
        autoplay: false,
        centerMode: true,
        centerPadding: "0px",
        prevArrow: $(".left-special"),
        nextArrow: $(".right-special"),
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
      });
    },
  },
};
</script>

<style>
.specialCarousel_mobile .slick-slide {
  opacity: 0.4;
  transition: opacity 0.3s ease;
}
.specialCarousel_mobile .slick-slide .paraspecial {
  opacity: 0;
}
.specialCarousel_mobile .slick-center .paraspecial {
  opacity: 1;
}
.specialCarousel_mobile .slick-center {
  padding-top: 14px;
  opacity: 1;
  transform: scale(1.6);
  transition: all 0.4s ease-in-out;
  max-width: 100%;
}
</style>
