<template>
    <div>
      <headerView />
    <section class="max-w-7xl mx-auto mt-[3rem] lg:mt-[12rem] lg:mb-[6rem] justify-center items-center m-auto">
    <div class="container flex flex-col items-center text-center m-auto justify-center align-middle md:w-4/12 mt-8"> 
        <h1 class="text-3xl mb-3">404 Error!. <br><br>The page you're trying to access cannot be found.</h1>
        
        </div>
    </section>
    <footerView />
  </div>
</template>
<script>
import headerView from "@/components/common/header.vue";
import footerView from "@/components/common/footer.vue";

export default {
    name: 'PageNotFound',
    components: {
    headerView,
    footerView,
  },
    created() {
        
    },
};
</script>

<style>

</style>