<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  box-sizing: border-box
}

</style>
