<template>
  <div
    class="bg-gradient-to-b from-[#FFFFFF] to-[#EFEFEF] lg:h-[600px] px-5 lg:px-8 py-6"
  >
    <div class="max-w-7xl mx-auto pb-5 lg:py-10">
      <div>
        <h1 class="text-[30px] lg:text-[60px] leading-tight text-center">
          <span class="text-[#EC027C]">Affiliated</span><span class="text-[#18479E]"> to and</span><span class="text-[#EC027C]"> Accredited </span><span class="text-[#18479E]">by the Best</span>
        </h1>
        <!-- desktop -->
        <div class="hidden lg:block py-10">
          <div class="flex justify-center items-center">
            <div class="border border-[#18479E] rounded-full p-3 prev-aff cursor-pointer">
              <img
                src="@/assets/home/affiliated/Icon feather-arrow-left@2x.png"
                alt=""
                srcset=""
                class="w-[16px] h-[16px]"
              />
            </div>
            <div
              class="flex flex-row lg:justify-evenly max-w-5xl mx-auto affiliatedCarousel h-[300px]"
            >
              <div
                v-for="Categorie in Categories"
                :key="Categorie.id"
                class="w-full flex justify-center items-center h-full"
              >
                <div
                  class="flex flex-col gap-4 justify-center items-center w-full h-[350px]"
                >
                  <div
                    class="border border-[#D1D1D1] p-4 rounded-full cards bg-[#ffffff]"
                  >
                    <img
                      :src="Categorie.image"
                      alt=""
                      class="lg:w-[60px] lg:h-[60px] xl:w-[70px] xl:h-[70px] object-contain"
                    />
                  </div>
                  <p
                    v-html="Categorie.title"
                    class="text-center xyz w-full h-10 text-sm"
                  ></p>
                </div>
              </div>
            </div>
            <div class="border border-[#18479E] rounded-full p-3 next-aff cursor-pointer">
              <img
                src="@/assets/home/affiliated/Icon feather-arrow-right@2x.png"
                alt=""
                srcset=""
                class="w-[16px] h-[16px]"
              />
            </div>
          </div>
        </div>
        <!-- mobile -->
        <div class="lg:hidden block py-10">
          <div class="w-full">
            <div
              class="flex justify-between items-center gap-4 affiliatedCarousel_mobile h-[260px]"
            >
              <div
                v-for="Categorie in Categories"
                :key="Categorie.id"
                class="w-full flex justify-center items-center h-full"
              >
                <div
                  class="flex flex-col gap-4 justify-center items-center w-full h-[210px]"
                >
                  <div
                    class="border border-[#D1D1D1] p-5 rounded-full card_mobile bg-[#ffffff]"
                  >
                    <img
                      :src="Categorie.image"
                      alt=""
                      class="w-[40px] h-[40px]"
                    />
                  </div>
                  <p
                    v-html="Categorie.title"
                    class="text-center text-xs para_mobile w-full h-10"
                  ></p>
                </div>
              </div>
            </div>
            <div class="flex justify-center gap-4">
              <div
                class="border border-[#18479E] rounded-full p-3 left-affilited cursor-pointer"
              >
                <img
                  src="@/assets/home/affiliated/Icon feather-arrow-left@2x.png"
                  alt=""
                  srcset=""
                  class="w-[16px] h-[16px]"
                />
              </div>
              <div
                class="border border-[#18479E] rounded-full p-3 right-affilited bottom-[-40px] right-[110px] cursor-pointer"
              >
                <img
                  src="@/assets/home/affiliated/Icon feather-arrow-right@2x.png"
                  alt=""
                  srcset=""
                  class="w-[16px] h-[16px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "AffiliatedView",
  data() {
    return {
      Categories: [
        {
          id: 1,
          image: require("@/assets/home/affiliated/Sharda-Online-UGC.webp"),
          title: "UGC Entitled",
        },
        {
          id: 2,
          image: require("@/assets/home/affiliated/Sharda-Online-NIRF.webp"),
          title: "Ranked amongst the top 86th in India",
        },
        {
          id: 3,
          image: require("@/assets/home/affiliated/Sharda-Online-India-Today.webp"),
          title: "Ranked 5th in the number of patents granted, according to the India Today Survey",
        },
        {
          id: 4,
          image: require("@/assets/home/affiliated/Sharda-Online-ISO.webp"),
          title: "Awarded ISO 9001:2015 certification",
        },
        {
          id: 5,
          image: require("@/assets/home/affiliated/Sharda-Online-NIRF.webp"),
          title: "Ranked amongst the top 86th in India",
        },
        {
          id: 6,
          image: require("@/assets/home/affiliated/Sharda-Online-NAAC.webp"),
          title: "NAAC A+ Graded",
        },
      ],
    };
  },
  beforeMount() {
    const elementToRemoveDes = document.querySelector(".affiliatedCarousel");
    const elementToRemoveMob = document.querySelector(
      ".affiliatedCarousel_mobile"
    );
    if (elementToRemoveDes) {
      elementToRemoveDes.remove();
    }
    if (elementToRemoveMob) {
      elementToRemoveDes.remove();
    }
  },
  mounted() {
    this.initializeCarousel();
  },
  methods: {
    initializeCarousel() {
      $(`.affiliatedCarousel`).slick({
        infinite: true,
        autoplay: true,
        centerMode: true,
        centerPadding: "10px",
        prevArrow: $(".prev-aff"),
        nextArrow: $(".next-aff"),
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: false,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1026,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
        ],
      });
      $(`.affiliatedCarousel_mobile`).slick({
        infinite: true,
        autoplay: true,
        centerMode: true,
        centerPadding: "0px",
        prevArrow: $(".left-affilited"),
        nextArrow: $(".right-affilited"),
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
      });
    },
  },
};
</script>

<style>
@media screen and (min-width: 1024px) and (max-width: 1280px) {
  .affiliatedCarousel {
    width: 80%;
  }
  .affiliatedCarousel .slick-slide {
    opacity: 0.4;
    transition: opacity 0.3s ease;
  }
}
.affiliatedCarousel .slick-slide {
  opacity: 0.4;
  transition: opacity 0.3s ease;
}
.affiliatedCarousel .slick-slide .xyz {
  opacity: 0;
}
.affiliatedCarousel .slick-current .xyz {
  opacity: 1;
}

.affiliatedCarousel .slick-center {
  opacity: 1;
  transform: scale(1.4);
  transition: all 0.4s ease-in-out;
  max-width: 100%;
}
.affiliatedCarousel .slick-center .cards {
  border: 1px solid #18479e;
}
.affiliatedCarousel_mobile .slick-slide {
  opacity: 0.4;
  transition: opacity 0.3s ease;
  /* padding-top: 10px; */
  height: 250px;
}
.affiliatedCarousel_mobile .slick-slide .para_mobile {
  opacity: 0;
}
.affiliatedCarousel_mobile .slick-center .para_mobile {
  opacity: 1;
}
.affiliatedCarousel_mobile .slick-center {
  opacity: 1;
  transform: scale(1.4);
  transition: all 0.4s ease-in-out;
  max-width: 100%;
}
.affiliatedCarousel_mobile .slick-center .card_mobile {
  border: 1px solid blue;
}
</style>
