<template>
  <div class="pb-8 pt-12 px-5 lg:px-8 bg-[#ffffff]">
    <div class="w-full max-w-6xl mx-auto lg:px-4">
      <div class="flex flex-col gap-3 lg:gap-6">
        <div class="hidden lg:flex lg:justify-between">
          <h2 class="text-[30px] lg:text-[60px] text-left text-[#18479E]">
            Hands-on <span class="text-[#EC027C]">Curriculum</span>
          </h2>
          <div class="flex items-center">
            <a :href="elective.brochures" target="_blank">
              <button
                class="w-[200px] h-[50px] text-[16px] font-bold text-[#EC027C] border border-[#EC027C] rounded-3xl"
              >
                Download Brochure
              </button></a
            >
          </div>
        </div>
        <h2 class="lg:hidden text-[30px] text-left text-[#18479E]">
          Hands-on <span class="text-[#EC027C]">Curriculum</span>
        </h2>

        <div class="flex flex-col gap-3 lg:gap-5">
          <div v-if="elective" class="mt-5 lg:mt-8">
            <div
              v-for="item in elective.syllabus"
              :key="item.id"
              @click="toggleAccordin(item.id)"
              class="pb-1 mb-4 border-b-[1px] border-[#D1D1D1]"
            >
              <div
                class="flex justify-between mb-2 cursor-pointer"
                :class="{
                  'pb-2 border-b border-[#18479E]': activeContentId === item.id,
                  '': activeContentId !== item.id,
                }"
              >
                <div
                  :class="{
                    'text-[#18479E]': activeContentId === item.id,
                    'text-[#EC027C]': activeContentId !== item.id,
                  }"
                  class="text-[20px] lg:text-[30px]"
                >
                  {{ item.semester }}
                </div>
                <div
                  v-if="activeContentId != item.id"
                  class="flex items-center"
                >
                  <img
                    src="@/assets/home/header/plus.svg"
                    alt="plus"
                    class="w-[30px] h-[30px]"
                  />
                </div>
                <div v-else class="flex items-center">
                  <img
                    src="@/assets/home/header/minus.svg"
                    alt="minus"
                    class="w-[30px] h-[30px]"
                  />
                </div>
              </div>
              <div class="lg:py-5" v-show="item.id === activeContentId">
                <ul
                  class="grid grid-cols lg:grid-rows-3 lg:grid-flow-col gap-3"
                >
                  <li
                    v-for="(listItem, index) in item.topics"
                    :key="index"
                    class="flex items-start text-[16px] lg:text-[18px] -order-1"
                  >
                    <div class="w-[20px] h-full pt-2">
                      <img
                        src="@/assets/home/career/check-solid.svg"
                        alt="check"
                        class="w-[12px] h-[12px]"
                      />
                    </div>
                    <span class="">{{ listItem }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:hidden w-full flex justify-center">
          <a :href="elective.brochures" target="_blank">
            <button
              class="w-[200px] h-[50px] text-[16px] font-bold text-[#EC027C] border border-[#EC027C] rounded-3xl"
            >
              Download Brochure
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurriculumView",
  props: ["elective"],

  data() {
    return {
      semester: [
        {
          id: 1,
          title: "1st Semester",
          content: [
            "Management Concepts and Practices",
            "Managerial Effectiveness and Ethics",
            "Accounting and Finance",
            "Quantitative Techniques and Analytics",
            "Principles of Economics and Markets",
            "Organizational Behavior and Human",
            "Resources Management",
          ],
        },
        {
          id: 2,
          title: "2nd Semester",
          content: [
            "Management Concepts and Practices",
            "Managerial Effectiveness and Ethics",
            "Accounting and Finance",
            "Quantitative Techniques and Analytics",
            "Principles of Economics and Markets",
            "Organizational Behavior and Human",
            "Resources Management",
          ],
        },
        {
          id: 3,
          title: "3rd Semester",
          content: [
            "Management Concepts and Practices",
            "Managerial Effectiveness and Ethics",
            "Accounting and Finance",
            "Quantitative Techniques and Analytics",
            "Principles of Economics and Markets",
            "Organizational Behavior and Human",
            "Resources Management",
          ],
        },
        {
          id: 4,
          title: "4th Semester",
          content: [
            "Management Concepts and Practices",
            "Managerial Effectiveness and Ethics",
            "Accounting and Finance",
            "Quantitative Techniques and Analytics",
            "Principles of Economics and Markets",
            "Organizational Behavior and Human",
            "Resources Management",
          ],
        },
      ],
      activeContentId: "1st Semester",
    };
  },
  methods: {
    stripHtmlTags(input) {
      return input.replace(/<\/?[^>]+(>|$)/g, "");
    },
    toggleAccordin(index) {
      this.activeContentId = this.activeContentId === index ? null : index;
    },
  },
};
</script>

<style></style>
